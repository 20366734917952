import { createSelector } from "reselect";

export const getCommonStore = (state) => state.common;

export const checkNetworkConnection = createSelector(getCommonStore, (common) => common.networkConnected);

export const getComuni = createSelector(getCommonStore, (common) => common.comuni.data);
export const fetchComuniEnded = createSelector(getCommonStore, (common) => common.comuni.fetchEnded);

export const getClassificazioni = createSelector(getCommonStore, (common) => common.classificazioni.data);
export const fetchClassificazioniEnded = createSelector(getCommonStore, (common) => common.classificazioni.fetchEnded);

export const getTipiProvenienza = createSelector(getCommonStore, (common) => common.tipiProvenienza.data);
export const fetchTipiProvenienzaEnded = createSelector(getCommonStore, (common) => common.tipiProvenienza.fetchEnded);

export const getSpecie = createSelector(getCommonStore, (common) => common.specie.data);
export const fetchSpecieEnded = createSelector(getCommonStore, (common) => common.specie.fetchEnded);

export const getSpecieTotali = createSelector(getCommonStore, (common) => common.specietotali.data);
export const fetchSpecieTotaliEnded = createSelector(getCommonStore, (common) => common.specietotali.fetchEnded);

export const getClassiIspra = createSelector(getCommonStore, (common) => common.classiIspra.data);
export const fetchClassiIspraEnded = createSelector(getCommonStore, (common) => common.classiIspra.fetchEnded);

export const getListaUnitaPopolazione = createSelector(getCommonStore, (common) => common.listaUnitaPopolazione.data);
export const fetchListaUnitaPopolazioneEnded = createSelector(getCommonStore, (common) => common.listaUnitaPopolazione.fetchEnded); //prettier-ignore

export const getEntitaRilevate = createSelector(getCommonStore, (common) => common.entitaRilevate.data);
export const fetchEntitaRilevateEnded = createSelector(getCommonStore, (common) => common.entitaRilevate.fetchEnded);

export const getTarget = createSelector(getCommonStore, (common) => common.target.data);
export const fetchTargetEnded = createSelector(getCommonStore, (common) => common.target.fetchEnded);

export const getSottogruppi = createSelector(getCommonStore, (common) => common.sottogruppi.data);
export const fetchSottogruppiEnded = createSelector(getCommonStore, (common) => common.sottogruppi.fetchEnded);

export const getSviluppi = createSelector(getCommonStore, (common) => common.sviluppi.data);
export const fetchSviluppiEnded = createSelector(getCommonStore, (common) => common.sviluppi.fetchEnded);

export const getAbitudini = createSelector(getCommonStore, (common) => common.abitudini.data);
export const fetchAbitudiniEnded = createSelector(getCommonStore, (common) => common.abitudini.fetchEnded);

export const getPosition = createSelector(getCommonStore, (common) => common.position);

export const showAppleInstallationDrawer = createSelector(getCommonStore, (common) => common.showAppleInstallationDrawer);
