import React, { Suspense, lazy, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Components } from "ld_core";
import {
  PATH_HOME,
  PATH_CERCA,
  PATH_SEGNALA,
  PATH_MAPPA_SPECIE_ALIENE,
  PATH_PRO,
  PATH_SEGNALAZIONI,
  PATH_VISUALIZZA_SEGNALAZIONE,
  PATH_CREDITS,
} from "../path";
import { changeNetworkConnection, checkNetworkConnection } from "../modules/common";

const { ErrorPage, LDMessage } = Components;
const LazyCerca = lazy(() => import("../components/pages/LazyCerca"));
const LazySegnalazioni = lazy(() => import("../components/pages/LazySegnalazioni"));
const LazyVisualizzaSegnalazione = lazy(() => import("../components/pages/LazyVisualizzaSegnalazione"));
const LazySegnala = lazy(() => import("../components/pages/segnala/LazySegnala"));
const LazyMappaSpecieAliene = lazy(() => import("../components/pages/LazyMappaSpecieAliene"));
const LazyHomePage = lazy(() => import("../components/pages/homePage/LazyHome"));
const LazyCredits = lazy(() => import("../components/pages/LazyCredits"));
const LazyLandingPage = lazy(() => import("../components/pages/LazyLandingPage"));
export const SwitchContent = () => {
  const isNetworkConnected = useSelector(checkNetworkConnection);
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("online", () => {
      if (!isNetworkConnected) dispatch(changeNetworkConnection(true));
    });

    window.addEventListener("offline", () => {
      if (isNetworkConnected) dispatch(changeNetworkConnection(false));
    });
    // eslint-disable-next-line
  }, [isNetworkConnected]);

  return (
    <div id="mainContent">
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path={PATH_HOME}>
            <LazyHomePage />
          </Route>
          <Route exact path={PATH_CERCA}>
            <LazyCerca />
          </Route>
          <Route exact path={PATH_SEGNALA}>
            <LazySegnala />
          </Route>
          <Route exact path={PATH_MAPPA_SPECIE_ALIENE}>
            <LazyMappaSpecieAliene />
          </Route>
          <Route exact path={PATH_PRO + "/:path?"} render={(routeProps) => <LazyLandingPage path={routeProps.match.params.path} />} />
          <Route exact path={PATH_SEGNALAZIONI}>
            <LazySegnalazioni />
          </Route>
          <Route
            exact
            path={PATH_VISUALIZZA_SEGNALAZIONE + "/:idSegnalazione"}
            render={(routeProps) => <LazyVisualizzaSegnalazione idSegnalazione={routeProps.match.params.idSegnalazione} />}
          />
          <Route exact path={PATH_CREDITS}>
            <LazyCredits />
          </Route>
          <Route path="*" exact={true}>
            <ErrorPage errorMessage={<LDMessage id="ld.common.pageNotFound" />} />
          </Route>
        </Switch>
      </Suspense>
    </div>
  );
};
