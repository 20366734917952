export const FETCH_ENTITA = "FETCH_ENTITA@libioss";
export const FETCH_ENTITA_SUCCESS = "FETCH_ENTITA_SUCCESS@libioss";
export const FETCH_IMMAGINE_ENTITA = "FETCH_IMMAGINE_ENTITA@libioss";
export const FETCH_IMMAGINE_ENTITA_SUCCESS = "FETCH_IMMAGINE_ENTITA_SUCCESS@libioss";
export const RESET_ENTITA = "RESET_ENTITA@libioss";
export const FETCH_LISTA_SEGNALAZIONI = "FETCH_LISTA_SEGNALAZIONI@libioss";
export const FETCH_LISTA_SEGNALAZIONI_SUCCESS = "FETCH_LISTA_SEGNALAZIONI_SUCCESS@libioss";
export const FETCH_INFO_LOGIN = "FETCH_INFO_LOGIN@libioss";
export const FETCH_INFO_LOGIN_SUCCESS = "FETCH_INFO_LOGIN_SUCCESS@libioss";
export const RESET_INFO_LOGIN = "RESET_INFO_LOGIN@libioss";
export const SET_FIELD_SEGNALAZIONE = "SET_FIELD_SEGNALAZIONE@libioss";
export const RESET_SEGNALAZIONE = "RESET_SEGNALAZIONE@libioss";
export const SALVA_SEGNALAZIONE = "SALVA_SEGNALAZIONE@libioss";
export const INVIA_SEGNALAZIONE = "INVIA_SEGNALAZIONE@libioss";
export const INVIA_SEGNALAZIONE_SUCCESS = "INVIA_SEGNALAZIONE_SUCCESS@libioss";
export const RECUPERA_SEGNALAZIONE = "RECUPERA_SEGNALAZIONE@libioss";
export const RIMUOVI_SEGNALAZIONE = "RIMUOVI_SEGNALAZIONE@libioss";
export const DOWNLOAD_CSV = "DOWNLOAD_CSV@libioss";
