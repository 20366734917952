import { injectIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Layout, Image, Button, Row, Col } from "antd";
import { Utility, Components } from "ld_core";
import { PATH_CREDITS, PATH_ARPAL, PATH_NOTE_LEGALI_ARPAL, PATH_PRIVACY_ARPAL, PATH_DICH_ACCESSIBILITA } from "../path";
import arpal from "../styles/images/arpal.png";
import snpa from "../styles/images/snpa.png";

const { configUtils } = Utility;
const { getLDMessageString } = Components;
const { isMobileMode } = configUtils;
const { Footer } = Layout;

export const Contatti = ({ className }) => (
  <div className={className}>
    <span>Via Bombrini 8 - 16149 Genova</span>
    <span>C.F e P.Iva 01305930107</span>
    <span>Tel +39 010 64371</span>
  </div>
);

const AppFooterBase = ({ intl }) => {
  const mobileMode = useSelector(isMobileMode);
  return (
    !mobileMode && (
      <Footer className="appFooter">
        <Row className="info" justify="space-around" align="middle">
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <a href={PATH_ARPAL} target="_blank" rel="noopener noreferrer">
              <Image id="arpalLogo" alt="Logo Arpal" src={arpal} preview={false} />
            </a>
          </Col>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <Image id="snpaLogo" alt="Logo Snpa" src={snpa} preview={false} />
          </Col>
          <Col xs={12} sm={6} md={6} lg={6} xl={6}>
            <Contatti className="contatti" justify-content="center" />
          </Col>
        </Row>
        <Row className="footerButton">
          <Col>
            <Link to={PATH_CREDITS}>
              <Button>{getLDMessageString(intl, "ld.common.credits")} </Button>
            </Link>
          </Col>
          <Col>
            <a href={PATH_NOTE_LEGALI_ARPAL} target="_blank" rel="noopener noreferrer">
              <Button>{getLDMessageString(intl, "ld.common.noteLegali")}</Button>
            </a>
          </Col>
          <Col>
            <a href={PATH_PRIVACY_ARPAL} target="_blank" rel="noopener noreferrer">
              <Button>{getLDMessageString(intl, "ld.common.privacy")}</Button>
            </a>
          </Col>
          <Col>
            <a href={PATH_DICH_ACCESSIBILITA} target="_blank" rel="noopener noreferrer">
              <Button>{getLDMessageString(intl, "ld.common.accessibilita")}</Button>
            </a>
          </Col>
        </Row>
      </Footer>
    )
  );
};

export const AppFooter = injectIntl(AppFooterBase);
