export const IS_NETWORK_CONNECTED = "IS_NETWORK_CONNECTED@common";
export const FETCH_COMUNI = "FETCH_COMUNI@common";
export const FETCH_COMUNI_SUCCESS = "FETCH_COMUNI_SUCCESS@common";
export const FETCH_CLASSIFICAZIONI = "FETCH_CLASSIFICAZIONI@common";
export const FETCH_CLASSIFICAZIONI_SUCCESS = "FETCH_CLASSIFICAZIONI_SUCCESS@common";
export const FETCH_TIPI_PROVENIENZA = "FETCH_TIPI_PROVENIENZA@common";
export const FETCH_TIPI_PROVENIENZA_SUCCESS = "FETCH_TIPI_PROVENIENZA_SUCCESS@common";
export const FETCH_SPECIE = "FETCH_SPECIE@common";
export const FETCH_SPECIE_SUCCESS = "FETCH_SPECIE_SUCCESS@common";
export const FETCH_SPECIETOTALI = "FETCH_SPECIETOTALI@common";
export const FETCH_SPECIETOTALI_SUCCESS = "FETCH_SPECIETOTALI_SUCCESS@common";
export const SET_POSITION = "SET_POSITION@common";
export const FETCH_CLASSI_ISPRA = "FETCH_CLASSI_ISPRA@common";
export const FETCH_CLASSI_ISPRA_SUCCESS = "FETCH_CLASSI_ISPRA_SUCCESS@common";
export const FETCH_LISTA_UNITA_POPOLAZIONE = "FETCH_LISTA_UNITA_POPOLAZIONE@common";
export const FETCH_LISTA_UNITA_POPOLAZIONE_SUCCESS = "FETCH_LISTA_UNITA_POPOLAZIONE_SUCCESS@common";
export const FETCH_ENTITA_RILEVATE = "FETCH_ENTITA_RILEVATE@common";
export const FETCH_ENTITA_RILEVATE_SUCCESS = "FETCH_ENTITA_RILEVATE_SUCCESS@common";
export const FETCH_TARGET = "FETCH_TARGET@common";
export const FETCH_TARGET_SUCCESS = "FETCH_TARGET_SUCCESS@common";
export const FETCH_SOTTOGRUPPI = "FETCH_SOTTOGRUPPI@common";
export const FETCH_SOTTOGRUPPI_SUCCESS = "FETCH_SOTTOGRUPPI_SUCCESS@common";
export const FETCH_SVILUPPI = "FETCH_SVILUPPI@common";
export const FETCH_SVILUPPI_SUCCESS = "FETCH_SVILUPPI_SUCCESS@common";
export const FETCH_ABITUDINI = "FETCH_ABITUDINI@common";
export const FETCH_ABITUDINI_SUCCESS = "FETCH_ABITUDINI_SUCCESS@common";
export const SHOW_APPLE_INSTALLATION_INFO = "SHOW_APPLE_INSTALLATION_INFO@common";
export const REHYDRATE_WITH_SESSION_STORAGE = "REHYDRATE_WITH_SESSION_STORAGE@common";
