import { all, put, takeLatest, takeEvery, select } from "redux-saga/effects";
import {
  FETCH_ENTITA,
  FETCH_IMMAGINE_ENTITA,
  FETCH_LISTA_SEGNALAZIONI,
  FETCH_INFO_LOGIN,
  INVIA_SEGNALAZIONE,
  DOWNLOAD_CSV,
  fetchEntitaSuccess,
  fetchImmagineEntitaSuccess,
  fetchListaSegnalazioniSuccess,
  fetchInfoLoginSuccess,
  inviaSegnalazioneSuccess,
  preparaDettaglio,
  preparaListaSegnalazioni,
  preparaInfoLogin,
  preparaDatiPerSalvataggio,
  FAILED,
  SAVED,
  OPERATORE,
} from "./index";
import { getSpecieTotali } from "../common";
import { createRequest } from "../../tools";

function* fetchEntitaWorker(action) {
  try {
    const response = yield createRequest("GET", "/applibioss/api/specie/dettaglio/" + action.codSpecie);
    if (response.status === 200) {
      const infoFromResponse = preparaDettaglio(response.data);
      if (infoFromResponse) yield put(fetchEntitaSuccess(infoFromResponse));
      else throw new Error("Nessun dettaglio presente");
    } else throw response.message;
  } catch (e) {
    console.error("Errore durante il caricamento del dettaglio", action.codSpecie, " :", e);
    yield put(fetchEntitaSuccess(null));
  }
}

function* fetchImmagineEntitaWorker(action) {
  try {
    const response = yield createRequest("GET", "/applibioss/image/dettagliob64/" + action.nomeImmagine);
    if (response.status === 200) {
      const infoFromResponse = response.data;
      if (infoFromResponse) yield put(fetchImmagineEntitaSuccess(infoFromResponse));
      else throw new Error("Nessuna immagine recuperata");
    } else throw response.message;
  } catch (e) {
    console.error("Errore durante il recupero dell'immagine", action.nomeImmagine, " :", e);
    yield put(fetchImmagineEntitaSuccess(null));
  }
}

function* fetchListaSegnalazioniWorker(action) {
  const listaSpecie = yield select(getSpecieTotali);
  try {
    const response = yield createRequest("GET", "/applibioss/pro/api/" + (action.codTipoUtente === OPERATORE ? "osservazioni" : "segnalazioni") + "/lista"); //prettier-ignore
    if (response.status === 200) {
      const infoFromResponse = preparaListaSegnalazioni(response.data, listaSpecie, action.codTipoUtente === OPERATORE);
      if (infoFromResponse) yield put(fetchListaSegnalazioniSuccess(infoFromResponse));
      else throw new Error("Nessuna lista segnalazioni presente");
    } else throw response.message;
  } catch (e) {
    console.error("Errore durante il caricamento della lista delle segnalazioni:", e);
    yield put(fetchListaSegnalazioniSuccess([], listaSpecie));
  }
}

function* fetchInfoLoginWorker(action) {
  try {
    const response = yield createRequest("GET", "/applibioss/pro/api/userInfo");
    if (response.status === 200) {
      const infoFromResponse = preparaInfoLogin(response.data);
      if (infoFromResponse) yield put(fetchInfoLoginSuccess(infoFromResponse));
      else throw new Error("Nessuna informazione utente presente");
    } else throw response.message;
  } catch (e) {
    console.error("Errore durante il login dell'utente:", e);
    yield put(fetchInfoLoginSuccess(null));
  }
}

function* fetchInviaSegnalazioneWorker(action) {
  const isOperatore = action.codTipoUtente === OPERATORE;
  try {
    const payload = preparaDatiPerSalvataggio(action.values);
    const response = yield createRequest("POST", "/applibioss/pro/api/" + (isOperatore ? "osservazioni" : "segnalazioni") + "/inserisci", null, null, payload); //prettier-ignore
    if (response.status === 200) yield put(inviaSegnalazioneSuccess(SAVED, isOperatore));
    else throw response.message;
  } catch (e) {
    console.error("Errore durante l'invio della segnalazione/osservazione :", e);
    yield put(inviaSegnalazioneSuccess(FAILED, isOperatore));
  }
}

function* downloadCsvWorker(action) {
  try {
    const response = yield createRequest("POST", "/applibioss/api/specie/listacsv", null, null, action.listaSpecie); //prettier-ignore
    if (response.status === 200) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", response.headers.filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else throw response.message;
  } catch (e) {
    console.error("Errore durante il download del file csv", e);
  }
}

function* fetchEntitaWatcher() {
  yield takeLatest(FETCH_ENTITA, fetchEntitaWorker);
}

function* fetchImmagineEntitaWatcher() {
  yield takeEvery(FETCH_IMMAGINE_ENTITA, fetchImmagineEntitaWorker);
}

function* fetchListaSegnalazioniWatcher() {
  yield takeLatest(FETCH_LISTA_SEGNALAZIONI, fetchListaSegnalazioniWorker);
}

function* fetchInfoLoginWatcher() {
  yield takeLatest(FETCH_INFO_LOGIN, fetchInfoLoginWorker);
}

function* fetchInviaSegnalazioneWatcher() {
  yield takeLatest(INVIA_SEGNALAZIONE, fetchInviaSegnalazioneWorker);
}

function* downloadCsvWatcher() {
  yield takeLatest(DOWNLOAD_CSV, downloadCsvWorker);
}

export function* libiossWatchers() {
  yield all([
    fetchEntitaWatcher(),
    fetchImmagineEntitaWatcher(),
    fetchListaSegnalazioniWatcher(),
    fetchInfoLoginWatcher(),
    fetchInviaSegnalazioneWatcher(),
    downloadCsvWatcher(),
  ]);
}
