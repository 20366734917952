import { all, put, takeLatest } from "redux-saga/effects";
import { Utility } from "ld_core";
import { createRequest } from "../../tools";

const { GET_CONFIG, GET_TOKEN, addSessionStorage, getConfigSuccess, getToken } = Utility.configUtils;

function* getConfigWorker() {
  try {
    const response = yield createRequest("GET", "/applibioss/api/config/getConfig");
    if (response.status === 200) {
      const config = response.data;
      yield put(getConfigSuccess(config));
      if (config.tokenUrl && config.tokenUrl !== undefined) yield put(getToken(response.data.tokenUrl));
    } else throw response.message;
  } catch (e) {
    console.error("Errore durante il caricamento del file di configurazione:", e);
    yield put(getConfigSuccess(null));
  }
}

function* getTokenWorker(action) {
  try {
    const response = yield createRequest("GET", action.tokenUrl);
    if (response.status === 200) addSessionStorage(response.data);
    else throw response.message;
  } catch (e) {
    console.error("Errore durante il recupero del token:", e);
  }
}

function* getConfigWatcher() {
  yield takeLatest(GET_CONFIG, getConfigWorker);
}

function* getTokenWatcher() {
  yield takeLatest(GET_TOKEN, getTokenWorker);
}

export function* configurationWatchers() {
  yield all([getConfigWatcher(), getTokenWatcher()]);
}
