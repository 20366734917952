import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Divider, Layout, Image, Button, Drawer, Row, Col, Menu, Dropdown } from "antd";
import { Utility, Components } from "ld_core";
import { BancaDati, Cerca, Credits, Archivio, Privacy, Home, NoteLegali, Segnala, UserPrimario, Logout, Bars, Mappa } from "../components/icons";
import { Contatti } from "./AppFooter";
import arpal from "../styles/images/arpal.png";
import logoNegativo from "../styles/images/logo_negativo.png";
import {
  PATH_HOME,
  PATH_BASE_DATI,
  PATH_CERCA,
  PATH_SEGNALA,
  PATH_MAPPA_SPECIE_ALIENE,
  PATH_CREDITS,
  PATH_SEGNALAZIONI,
  PATH_ARPAL,
  PATH_NOTE_LEGALI_ARPAL,
  PATH_PRIVACY_ARPAL,
  PATH_DICH_ACCESSIBILITA,
  PATH_LOGIN,
  PATH_LOGOUT,
} from "../path";
import { resetInfoLogin, getInfoLogin, OPERATORE } from "../modules/libioss";
import {
  fetchComuniEnded,
  fetchClassificazioniEnded,
  fetchSpecieEnded,
  fetchClassiIspraEnded,
  fetchListaUnitaPopolazioneEnded,
  fetchEntitaRilevateEnded,
  fetchTargetEnded,
  fetchSottogruppiEnded,
  fetchSviluppiEnded,
  fetchAbitudiniEnded,
  getCommonStore,
} from "../modules/common";
import { salvaInfoSuSessionStorage } from "../tools";

const { getLDMessageString } = Components;
const { isMobileMode } = Utility.configUtils;
const { Header } = Layout;
const { Item, SubMenu } = Menu;

const HOME = "home";
const CERCA = "cerca";
const SEGNALA = "segnala";
const MAPPA_ALIENE = "mappaSpecieAliene";
const CREDITS = "credits";
const NOTE_LEGALI = "noteLegali";
const PRIVACY = "privacy";
const ACCESSIBILITA = "accessibilita";
const ACCEDI = "accedi";
const USER = "user";
const SEGNALAZIONI = "segnalazioni";
const LOGOUT = "logout";

const submenuKeys = [SEGNALAZIONI, LOGOUT];

const FULL_PATH_LOGIN = window.location.origin + PATH_LOGIN;
const FULL_PATH_LOGOUT = window.location.origin + PATH_LOGOUT + window.location.origin + "/applibioss";

const AppHeaderBase = ({ intl }) => {
  const dispatch = useDispatch();
  const [loggedMenuVisible, setLoggedMenuVisible] = useState(null);
  const [current, setCurrent] = useState([HOME]);
  const [openKeys, setOpenKeys] = useState([]);
  const infoLogin = useSelector(getInfoLogin);
  const mobileMode = useSelector(isMobileMode);
  const [menuCollapsed, setMenuCollapsed] = useState(true);

  const commonStore = useSelector(getCommonStore);

  const isComuniEnded = useSelector(fetchComuniEnded);
  const isClassificazioniEnded = useSelector(fetchClassificazioniEnded);
  const isSpecieEnded = useSelector(fetchSpecieEnded);
  const isClassiIspraEnded = useSelector(fetchClassiIspraEnded);
  const isListaUnitaPopolazioneEnded = useSelector(fetchListaUnitaPopolazioneEnded);
  const isEntitaRilevateEnded = useSelector(fetchEntitaRilevateEnded);
  const isTargetEnded = useSelector(fetchTargetEnded);
  const isSottogruppiEnded = useSelector(fetchSottogruppiEnded);
  const isSviluppiEnded = useSelector(fetchSviluppiEnded);
  const isAbitudiniEnded = useSelector(fetchAbitudiniEnded);

  const isFetchEnded =
    isComuniEnded &&
    isClassificazioniEnded &&
    isSpecieEnded &&
    isClassiIspraEnded &&
    isListaUnitaPopolazioneEnded &&
    isEntitaRilevateEnded &&
    isTargetEnded &&
    isSottogruppiEnded &&
    isSviluppiEnded &&
    isAbitudiniEnded;

  const onMobileHomeClick = () => {
    setMenuCollapsed(true);
    setCurrent([]);
    setOpenKeys([]);
  };

  const onItemSelect = (item) => {
    setCurrent([item.key]);
    if (submenuKeys.includes(item.key)) setOpenKeys([USER]);
    else setOpenKeys([]);
    if (mobileMode) setMenuCollapsed(true);
  };

  const onSubmenuTitleClick = () => {
    if (openKeys.length === 0) setOpenKeys([USER]);
    else {
      if (!submenuKeys.includes(current[0])) setOpenKeys([]);
    }
  };

  useEffect(() => {
    if (infoLogin) {
      setOpenKeys([USER]);
      if (infoLogin.codTipoUtente && infoLogin.codTipoUtente === OPERATORE) {
        localStorage.setItem("isSegnalatoreOp", true);
        localStorage.setItem("entiRif", JSON.stringify(infoLogin.entiRiferimento));
      } else {
        localStorage.removeItem("isSegnalatoreOp");
        localStorage.removeItem("entiRif");
      }
    }
  }, [infoLogin]);

  const logout = () => dispatch(resetInfoLogin());

  const LogoApp = () => (
    <Link to={PATH_HOME}>
      <Image id="logo" alt="Logo Libioss" src={logoNegativo} preview={false} />
      <h1 className="sr-only">Osservatorio della Biodiversità Ligure -- aaaaaaaa</h1>
    </Link>
  );
  const LogoAppMenuMobile = () => (
    <Link to={PATH_HOME}>
      <Image id="logoMenuMobile" alt="Logo Libioss" src={logoNegativo} preview={false} />
      <h1 className="sr-only">Osservatorio della Biodiversità Ligure</h1>
    </Link>
  );

  const mobileLogout = () => {
    logout();
    setMenuCollapsed(true);
  };

  const MobileMenu = () => {
    return (
      <>
        <Button
          onClick={() => setMenuCollapsed(!menuCollapsed)}
          icon={<Bars className="menuBarsIcon" aria-label="Icona segnala" />}
          className="mobileMenuButton"
        />
        <Drawer
          className="menuDrawer mobileDrawer"
          closable={true}
          placement="right"
          visible={!menuCollapsed}
          onClose={() => setMenuCollapsed(!menuCollapsed)}
        >
          <Row className="mobileMenu">
            <Link className="linkTitle ant-row" to={PATH_HOME} onClick={onMobileHomeClick}>
              <Col span={24}>
                <LogoAppMenuMobile />
              </Col>
            </Link>
            <Col span={24}>
              <Menu onClick={onItemSelect} openKeys={openKeys} selectedKeys={current} mode="inline">
                <Item key={CERCA} icon={<Cerca aria-label="Icona cerca" />} disabled={!isFetchEnded}>
                  <Link to={PATH_CERCA}>{getLDMessageString(intl, "ld.common.cerca")}</Link>
                </Item>
                <Item key={SEGNALA} icon={<Segnala aria-label="Icona segnala" />} disabled={!isFetchEnded}>
                  <Link to={PATH_SEGNALA}>{getLDMessageString(intl, "ld.common.segnala")}</Link>
                </Item>
                <Item key={MAPPA_ALIENE} icon={<Mappa aria-label="Icona mappa specie aliene" />} disabled={!isFetchEnded}>
                  <Link to={PATH_MAPPA_SPECIE_ALIENE}>{getLDMessageString(intl, "ld.common.mappaAliene")}</Link>
                </Item>
                <Item key={CREDITS} icon={<Credits aria-label="Icona credits" />}>
                  <Link to={PATH_CREDITS}>{getLDMessageString(intl, "ld.common.credits")}</Link>
                </Item>
                <Item key={NOTE_LEGALI} icon={<NoteLegali aria-label="Icona note legali" />}>
                  <a href={PATH_NOTE_LEGALI_ARPAL} target="_blank" rel="noopener noreferrer">
                    {getLDMessageString(intl, "ld.common.noteLegali")}
                  </a>
                </Item>
                <Item key={PRIVACY} icon={<Privacy aria-label="Icona privacy" />}>
                  <a href={PATH_PRIVACY_ARPAL} target="_blank" rel="noopener noreferrer">
                    {getLDMessageString(intl, "ld.common.privacy")}
                  </a>
                </Item>
                <Item key={ACCESSIBILITA} icon={<Credits aria-label="Icona dichiarazione accessibilità" />}>
                  <a href={PATH_DICH_ACCESSIBILITA} target="_blank" rel="noopener noreferrer">
                    {getLDMessageString(intl, "ld.common.accessibilita")}
                  </a>
                </Item>
                {!infoLogin && (
                  <>
                    <Divider />
                    <Item key={ACCEDI}>
                      <a href={FULL_PATH_LOGIN} onClick={() => salvaInfoSuSessionStorage(PATH_HOME, commonStore)} rel="noopener noreferrer">
                        <Button className="mobileLoginButton" icon={<UserPrimario alt="Icona accedi" aria-label="Icona accedi" />}>
                          {getLDMessageString(intl, "ld.common.accedi")}
                        </Button>
                      </a>
                    </Item>
                  </>
                )}
                {infoLogin && infoLogin !== undefined && (
                  <SubMenu key={USER} icon={<UserPrimario />} title={infoLogin.nominativo} onTitleClick={onSubmenuTitleClick}>
                    <Item key={SEGNALAZIONI} icon={<Archivio aria-label="Icona archivio" />}>
                      <Link to={PATH_SEGNALAZIONI}>{getLDMessageString(intl, "ld.common.segnalazioni")}</Link>
                    </Item>
                    <Item key={LOGOUT} icon={<Logout aria-label="Icona logout" />}>
                      <a href={FULL_PATH_LOGOUT} onClick={mobileLogout} rel="noopener noreferrer">
                        {getLDMessageString(intl, "ld.common.logout")}
                      </a>
                    </Item>
                  </SubMenu>
                )}
              </Menu>
            </Col>
          </Row>
          <Row className="mobileFooter">
            <Col span={24}>
              <Divider />
              <a href={PATH_ARPAL} target="_blank" rel="noopener noreferrer">
                <Image id="arpal" alt="Logo Arpal" src={arpal} preview={false} />
              </a>
              <Contatti className="contatti" />
            </Col>
          </Row>
        </Drawer>
      </>
    );
  };

  const loggedMenu = () => (
    <>
      <Menu onClick={onItemSelect} selectedKeys={[current]} mode="vertical">
        <Item key={SEGNALAZIONI} icon={<Archivio alt="Icona archivio segnalazioni" aria-label="Icona archivio" />}>
          <Link alt="Vai all'archivio segnalazioni" to={PATH_SEGNALAZIONI}>
            {getLDMessageString(intl, "ld.common.segnalazioni")}
          </Link>
        </Item>
        <Item key={LOGOUT} icon={<Logout alt="Icona logout" aria-label="Icona logout" />}>
          <a href={FULL_PATH_LOGOUT} onClick={mobileLogout} rel="noopener noreferrer">
            {getLDMessageString(intl, "ld.common.logout")}
          </a>
        </Item>
      </Menu>
    </>
  );

  const DesktopHeader = () => (
    <Header className="desktopHeader">
      <Row className="logo">
        <Col span={24}>
          <LogoApp />
        </Col>
      </Row>
      <Row className="navbar" justify="center">
        <Col xs={4}>
          <Link to={PATH_HOME} alt="Vai alla Home">
            <Button icon={<Home alt="Icona Home" aria-label="Icona home" />}>{getLDMessageString(intl, "ld.common.home")}</Button>
          </Link>
        </Col>
        <Col xs={4}>
          <a href={PATH_BASE_DATI} target="_blank" rel="noopener noreferrer" alt="Vai alla banca dati di Arpal">
            <Button icon={<BancaDati alt="Icona banca dati" aria-label="Icona banca dati" />}>
              {getLDMessageString(intl, "ld.common.bancaDati")}
            </Button>
          </a>
        </Col>
        <Col xs={4}>
          <Link to={PATH_CERCA} alt="Cerca">
            <Button icon={<Cerca alt="Icona cerca" aria-label="Icona ricerca" />} disabled={!isFetchEnded}>
              {getLDMessageString(intl, "ld.common.cerca")}
            </Button>
          </Link>
        </Col>
        <Col xs={4}>
          <Link to={PATH_SEGNALA} alt="Segnala">
            <Button icon={<Segnala alt="Icona segnala" aria-label="Icona segnala" />} disabled={!isFetchEnded}>
              {getLDMessageString(intl, "ld.common.segnala")}
            </Button>
          </Link>
        </Col>
        <Col xs={4}>
          <Link to={PATH_MAPPA_SPECIE_ALIENE} alt="Mappa specie aliene">
            <Button icon={<Mappa alt="Icona mappa specie aliene" aria-label="Icona mappa specie aliene" />} disabled={!isFetchEnded}>
              {getLDMessageString(intl, "ld.common.mappaAliene")}
            </Button>
          </Link>
        </Col>
        <Col xs={4} className="userOptions">
          {infoLogin && infoLogin !== undefined ? (
            <Dropdown
              overlayClassName="userOptionsDropdown"
              overlay={loggedMenu}
              onVisibleChange={() => setLoggedMenuVisible(!loggedMenuVisible)}
              visible={loggedMenuVisible}
              trigger={["click"]}
            >
              <Button icon={<UserPrimario alt="Icona utente" aria-label="Icona utente" />}>{infoLogin.nominativo}</Button>
            </Dropdown>
          ) : (
            <a href={FULL_PATH_LOGIN} onClick={() => salvaInfoSuSessionStorage(PATH_HOME, commonStore)} alt="Accedi" rel="noopener noreferrer">
              <Button icon={<UserPrimario alt="Icona accedi" aria-label="Icona accedi" />} disabled={!isFetchEnded}>
                {getLDMessageString(intl, "ld.common.accedi")}
              </Button>
            </a>
          )}
        </Col>
      </Row>
    </Header>
  );

  // prettier-ignore
  const MobileHeader = () => (
    <Header className="mobileHeader">
      <Row>
        <Col span={22} className="logo" alt="Logo Libioss"><LogoApp /></Col>
        <Col span={2} className="menu"><MobileMenu /></Col>
      </Row>
    </Header>
  );

  return mobileMode ? <MobileHeader /> : <DesktopHeader />;
};

export const AppHeader = injectIntl(AppHeaderBase);
