import { put, all } from "redux-saga/effects";
import { Utility } from "ld_core";
import { setExpiredSessionPath } from "./modules/expiredSession";
import { Invertebrati } from "./styles/images/iconeClassificazioneTmp";
import {
  Anthozoa,
  Arachnida,
  Ascidiacea,
  Bivalvia,
  Bryozoa,
  Chilopoda,
  Clitellata,
  Cnidaria,
  Crustacea,
  Cubozoa,
  Decapoda,
  Demospongiae,
  Diplopoda,
  Echinodermata,
  Gastropoda,
  Hydrozoa,
  Insecta,
  Malacostraca,
  Oligochaeta,
  Opisthobranchia,
  Platyhelminthes,
  Porifera,
  Rhabditophora,
  Turbellaria,
} from "./styles/images/iconeInvertebrati";

const { createRequest: apiCreateRequest } = Utility.configUtils;
const { setNotification } = Utility.notificationUtils;

export function* verifyLoggedResource(response) {
  if (response.headers["content-type"].split(";").includes("text/html")) yield put(setExpiredSessionPath(response.request.responseURL));
}

export function* dispatchResponseNotification(response) {
  const notificationList = response.data.lstResponseMessage;
  if (notificationList && notificationList !== undefined && notificationList.length > 0)
    yield all(
      notificationList.map((notification) => put(setNotification(notification.tipoMessaggio, notification.messaggio, notification.descrizione)))
    );
}

export function* createRequest(method, baseUrl, apiUrl, queryParams, payload, headers) {
  try {
    const response = yield apiCreateRequest(method, baseUrl, apiUrl, queryParams, payload, headers);
    return response;
  } catch (e) {
    throw new Error(e);
  }
}

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const salvaInfoSuSessionStorage = (redirectUrl, commonStore) => {
  sessionStorage.setItem("redirectUrl", redirectUrl);
  if (!localStorage.getItem("staticInfo")) localStorage.setItem("staticInfo", JSON.stringify(commonStore));
};

export const renderIconaInvertebrato = (classeTipoInvertebrato) => {
  switch (classeTipoInvertebrato) {
    case "Anthozoa":
      return Anthozoa;
    case "Arachnida":
      return Arachnida;
    case "Ascidiacea":
      return Ascidiacea;
    case "Bivalvia":
      return Bivalvia;
    case "Bryozoa":
      return Bryozoa;
    case "Chilopoda":
      return Chilopoda;
    case "Clitellata":
      return Clitellata;
    case "Cnidaria":
      return Cnidaria;
    case "Crustacea":
      return Crustacea;
    case "Cubozoa":
      return Cubozoa;
    case "Decapoda":
      return Decapoda;
    case "Demospongiae":
      return Demospongiae;
    case "Diplopoda":
      return Diplopoda;
    case "Echinodermata":
      return Echinodermata;
    case "Gastropoda":
      return Gastropoda;
    case "Hydrozoa":
      return Hydrozoa;
    case "Insecta":
      return Insecta;
    case "Malacostraca":
      return Malacostraca;
    case "Oligochaeta":
      return Oligochaeta;
    case "Opisthobranchia":
      return Opisthobranchia;
    case "Platyhelminthes":
      return Platyhelminthes;
    case "Porifera":
      return Porifera;
    case "Rhabditophora":
      return Rhabditophora;
    case "Turbellaria":
      return Turbellaria;
    default:
      return Invertebrati;
  }
};
