import {
  IS_NETWORK_CONNECTED,
  FETCH_COMUNI,
  FETCH_COMUNI_SUCCESS,
  FETCH_CLASSIFICAZIONI,
  FETCH_CLASSIFICAZIONI_SUCCESS,
  FETCH_TIPI_PROVENIENZA,
  FETCH_TIPI_PROVENIENZA_SUCCESS,
  FETCH_SPECIE,
  FETCH_SPECIE_SUCCESS,
  FETCH_SPECIETOTALI,
  FETCH_SPECIETOTALI_SUCCESS,
  SET_POSITION,
  FETCH_CLASSI_ISPRA,
  FETCH_CLASSI_ISPRA_SUCCESS,
  FETCH_LISTA_UNITA_POPOLAZIONE,
  FETCH_LISTA_UNITA_POPOLAZIONE_SUCCESS,
  FETCH_ENTITA_RILEVATE,
  FETCH_ENTITA_RILEVATE_SUCCESS,
  FETCH_TARGET,
  FETCH_TARGET_SUCCESS,
  FETCH_SOTTOGRUPPI,
  FETCH_SOTTOGRUPPI_SUCCESS,
  FETCH_SVILUPPI,
  FETCH_SVILUPPI_SUCCESS,
  FETCH_ABITUDINI,
  FETCH_ABITUDINI_SUCCESS,
  SHOW_APPLE_INSTALLATION_INFO,
  REHYDRATE_WITH_SESSION_STORAGE,
} from "./index";

export const changeNetworkConnection = (isConnected) => ({
  type: IS_NETWORK_CONNECTED,
  isConnected,
});

export const fetchComuni = () => ({
  type: FETCH_COMUNI,
});

export const fetchComuniSuccess = (comuni) => ({
  type: FETCH_COMUNI_SUCCESS,
  comuni,
});

export const fetchClassificazioni = () => ({
  type: FETCH_CLASSIFICAZIONI,
});

export const fetchClassificazioniSuccess = (classificazioni) => ({
  type: FETCH_CLASSIFICAZIONI_SUCCESS,
  classificazioni,
});

export const fetchTipiProvenienza = () => ({
  type: FETCH_TIPI_PROVENIENZA,
});

export const fetchTipiProvenienzaSuccess = (tipiProvenienza) => ({
  type: FETCH_TIPI_PROVENIENZA_SUCCESS,
  tipiProvenienza,
});

export const fetchSpecie = () => ({
  type: FETCH_SPECIE,
});

export const fetchSpecieSuccess = (specie) => ({
  type: FETCH_SPECIE_SUCCESS,
  specie,
});

export const fetchSpecieTotali = () => ({
  type: FETCH_SPECIETOTALI,
});

export const fetchSpecieTotaliSuccess = (specietotali) => ({
  type: FETCH_SPECIETOTALI_SUCCESS,
  specietotali,
});


export const setPosition = (latitude, longitude) => ({
  type: SET_POSITION,
  latitude,
  longitude,
});

export const fetchClassiIspra = () => ({
  type: FETCH_CLASSI_ISPRA,
});

export const fetchClassiIspraSuccess = (classiIspra) => ({
  type: FETCH_CLASSI_ISPRA_SUCCESS,
  classiIspra,
});

export const fetchListaUnitaPopolazione = () => ({
  type: FETCH_LISTA_UNITA_POPOLAZIONE,
});

export const fetchListaUnitaPopolazioneSuccess = (listaUnitaPopolazione) => ({
  type: FETCH_LISTA_UNITA_POPOLAZIONE_SUCCESS,
  listaUnitaPopolazione,
});

export const fetchEntitaRilevate = () => ({
  type: FETCH_ENTITA_RILEVATE,
});

export const fetchEntitaRilevateSuccess = (entitaRilevate) => ({
  type: FETCH_ENTITA_RILEVATE_SUCCESS,
  entitaRilevate,
});

export const fetchTarget = () => ({
  type: FETCH_TARGET,
});

export const fetchTargetSuccess = (target) => ({
  type: FETCH_TARGET_SUCCESS,
  target,
});

export const fetchSottogruppi = () => ({
  type: FETCH_SOTTOGRUPPI,
});

export const fetchSottogruppiSuccess = (sottogruppi) => ({
  type: FETCH_SOTTOGRUPPI_SUCCESS,
  sottogruppi,
});

export const fetchSviluppi = () => ({
  type: FETCH_SVILUPPI,
});

export const fetchSviluppiSuccess = (sviluppi) => ({
  type: FETCH_SVILUPPI_SUCCESS,
  sviluppi,
});

export const fetchAbitudini = () => ({
  type: FETCH_ABITUDINI,
});

export const fetchAbitudiniSuccess = (abitudini) => ({
  type: FETCH_ABITUDINI_SUCCESS,
  abitudini,
});

export const showAppleInstallationInfo = (value) => ({
  type: SHOW_APPLE_INSTALLATION_INFO,
  value,
});

export const rehydrateWithSessionStorage = (sessionStorageValues) => ({
  type: REHYDRATE_WITH_SESSION_STORAGE,
  sessionStorageValues,
});
