import update from "immutability-helper";
import { DATA, FAILED, SAVED, IMMAGINI } from "./constants";
import {
  FETCH_ENTITA_SUCCESS,
  FETCH_IMMAGINE_ENTITA_SUCCESS,
  RESET_ENTITA,
  FETCH_LISTA_SEGNALAZIONI_SUCCESS,
  FETCH_INFO_LOGIN_SUCCESS,
  RESET_INFO_LOGIN,
  SET_FIELD_SEGNALAZIONE,
  RESET_SEGNALAZIONE,
  SALVA_SEGNALAZIONE,
  INVIA_SEGNALAZIONE,
  INVIA_SEGNALAZIONE_SUCCESS,
  SAVING,
  RECUPERA_SEGNALAZIONE,
  RIMUOVI_SEGNALAZIONE,
} from "./index";
import { v4 as randomId } from "uuid";
import moment from "moment";

export const initialState = {
  dettaglio: {
    data: null,
    fetchEnded: false,
  },
  listaSegnalazioni: {
    data: null,
    fetchEnded: false,
  },
  infoLogin: {
    data: null,
    fetchEnded: false,
  },
  segnalazione: null,
};

const fetchEntitaSuccessHandler = (state, action) => {
  return update(state, {
    dettaglio: {
      data: { $set: action.entita },
      fetchEnded: { $set: true },
    },
  });
};

const fetchImmagineEntitaSuccessHandler = (state, action) => {
  const immagine = action.immagine;
  let newDettaglio = Object.assign({}, state.dettaglio.data);
  let listaImmagini = newDettaglio.listaImmagini !== undefined ? newDettaglio.listaImmagini : [];
  if (immagine) listaImmagini.push(immagine);
  newDettaglio.listaImmagini = listaImmagini;
  return update(state, {
    dettaglio: {
      data: { $set: newDettaglio },
    },
  });
};

const resetEntitaHandler = (state) => {
  return update(state, {
    dettaglio: { $set: initialState.dettaglio },
  });
};

const fetchListaSegnalazioniSuccessHandler = (state, action) => {
  return update(state, {
    listaSegnalazioni: {
      data: { $set: action.listaSegnalazioni },
      fetchEnded: { $set: true },
    },
  });
};

const fetchInfoLoginSuccessHandler = (state, action) => {
  return update(state, {
    infoLogin: {
      data: { $set: action.infoLogin },
      fetchEnded: { $set: true },
    },
  });
};

const resetInfoLoginHandler = (state) => {
  return update(state, {
    infoLogin: {
      data: { $set: initialState.infoLogin.data },
      fetchEnded: { $set: initialState.infoLogin.fetchEnded },
    },
  });
};

const setFieldSegnalazioneHandler = (state, action) => {
  let segnalazioneTmp = Object.assign({}, state.segnalazione);
  segnalazioneTmp[action.field] = action.value;
  return update(state, {
    segnalazione: { $set: segnalazioneTmp },
  });
};

const resetSegnalazioneHandler = (state) => {
  return update(state, {
    segnalazione: { $set: initialState.segnalazione },
  });
};

const salvaSegnalazioneHandler = (state, action) => {
  let segnalazioneTmp = Object.assign({}, state.segnalazione);
  segnalazioneTmp.status = SAVED;
  segnalazioneTmp[IMMAGINI] = action.segnalazione[IMMAGINI];
  let storageItem = action.isOperatore ? "segnalazioniOperatore" : "segnalazioniUtente";
  let segnalazioniInStorage = localStorage.getItem(storageItem)
    ? JSON.parse(localStorage.getItem(storageItem))
    : null;

  let tmpId = segnalazioneTmp.tmpId;
  if (tmpId === undefined) {
    tmpId = randomId();
    segnalazioneTmp.tmpId = tmpId;
  }
  if (!segnalazioniInStorage) localStorage.setItem(storageItem, JSON.stringify([segnalazioneTmp]));
  else {
    segnalazioniInStorage = segnalazioniInStorage.filter((segnalazione) => segnalazione.tmpId !== tmpId);
    segnalazioniInStorage.push(segnalazioneTmp);
    localStorage.setItem(storageItem, JSON.stringify(segnalazioniInStorage));
  }

  return update(state, {
    segnalazione: { $set: segnalazioneTmp },
  });
};

const inviaSegnalazioneHandler = (state) => {
  let segnalazioneTmp = Object.assign({}, state.segnalazione);
  segnalazioneTmp.status = SAVING;
  return update(state, {
    segnalazione: { $set: segnalazioneTmp },
  });
};

const inviaSegnalazioneSuccessHandler = (state, action) => {
  let segnalazioneTmp = Object.assign({}, state.segnalazione);
  const { esito, isOperatore } = action;
  let storageItem = isOperatore ? "segnalazioniOperatore" : "segnalazioniUtente";
  segnalazioneTmp.status = esito;
  let segnalazioniInStorage = localStorage.getItem(storageItem)
    ? JSON.parse(localStorage.getItem(storageItem))
    : null;

  if (esito === FAILED) {
    let tmpId = segnalazioneTmp.tmpId;
    if (tmpId === undefined) {
      tmpId = randomId();
      segnalazioneTmp.tmpId = tmpId;
    }
    if (!segnalazioniInStorage) localStorage.setItem(storageItem, JSON.stringify([segnalazioneTmp]));
    else {
      segnalazioniInStorage = segnalazioniInStorage.filter((segnalazione) => segnalazione.tmpId !== tmpId);
      segnalazioniInStorage.push(segnalazioneTmp);
      localStorage.setItem(storageItem, JSON.stringify(segnalazioniInStorage));
    }
  } else {
    //prettier-ignore
    if (segnalazioniInStorage && segnalazioniInStorage.find((segnalazione) => segnalazione.tmpId === segnalazioneTmp.tmpId) !== undefined)
      localStorage.setItem(storageItem, JSON.stringify(segnalazioniInStorage.filter((segnalazione) => segnalazione.tmpId !== segnalazioneTmp.tmpId)));
  }

  return update(state, {
    segnalazione: { $set: segnalazioneTmp },
  });
};

const recuperaSegnalazioneHandler = (state, action) => {
  let segnalazioneTmp = Object.assign({}, action.segnalazione);
  segnalazioneTmp[DATA] = moment(segnalazioneTmp[DATA]);
  return update(state, {
    segnalazione: { $set: segnalazioneTmp },
  });
};

const rimuoviSegnalazioneHandler = (state, action) => {
  let storageItem = action.isOperatore ? "segnalazioniOperatore" : "segnalazioniUtente";
  let listaSegnalazioniTmp = JSON.parse(localStorage.getItem(storageItem));
  listaSegnalazioniTmp = listaSegnalazioniTmp.filter((segnalazione) => segnalazione.tmpId !== action.tmpId);
  localStorage.setItem(storageItem, JSON.stringify(listaSegnalazioniTmp));
  return state;
};

export const libiossReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ENTITA_SUCCESS:
      return fetchEntitaSuccessHandler(state, action);
    case FETCH_IMMAGINE_ENTITA_SUCCESS:
      return fetchImmagineEntitaSuccessHandler(state, action);
    case RESET_ENTITA:
      return resetEntitaHandler(state);
    case FETCH_LISTA_SEGNALAZIONI_SUCCESS:
      return fetchListaSegnalazioniSuccessHandler(state, action);
    case FETCH_INFO_LOGIN_SUCCESS:
      return fetchInfoLoginSuccessHandler(state, action);
    case RESET_INFO_LOGIN:
      return resetInfoLoginHandler(state);
    case SET_FIELD_SEGNALAZIONE:
      return setFieldSegnalazioneHandler(state, action);
    case RESET_SEGNALAZIONE:
      return resetSegnalazioneHandler(state);
    case SALVA_SEGNALAZIONE:
      return salvaSegnalazioneHandler(state, action);
    case INVIA_SEGNALAZIONE:
      return inviaSegnalazioneHandler(state);
    case INVIA_SEGNALAZIONE_SUCCESS:
      return inviaSegnalazioneSuccessHandler(state, action);
    case RECUPERA_SEGNALAZIONE:
      return recuperaSegnalazioneHandler(state, action);
    case RIMUOVI_SEGNALAZIONE:
      return rimuoviSegnalazioneHandler(state, action);
    default:
      return state;
  }
};
