const common = {
  "ld.common.home": "Home",
  "ld.common.bancaDati": "Banca dati",
  "ld.common.cerca": "Cerca",
  "ld.common.segnala": "Segnala",
  "ld.common.mappaAliene": "Mappa densità specie aliene",
  "ld.common.segnalazioni": "Archivio segnalazioni",
  "ld.common.messaggi": "I tuoi messaggi",
  "ld.common.profilo": "Il mio profilo",
  "ld.common.accedi": "Accedi",
  "ld.common.accessoSpid": "Entra con lo spid",
  "ld.common.mancanzaSpid": "Non hai lo SPID?",
  "ld.common.credits": "Credits",
  "ld.common.noteLegali": "Note legali",
  "ld.common.privacy": "Privacy",
  "ld.common.welcome": "Benvenuto ",
  "ld.common.accessibilita": "Dichiarazione di Accessibilità",
  "ld.common.logout": "Logout",
  "ld.common.logoutSuccess": " disconnesso con successo",
  "ld.common.noData": "Nessun risultato",
  "ld.common.pageNotFound": "Pagina non trovata",
  "ld.common.yes": "Sì",
  "ld.common.no": "No",
};

const installazione = {
  "ld.installazione.header": "Installazione",
  "ld.installazione.istruzioni1": "Tocca",
  "ld.installazione.istruzioni2": "e poi ",
  "ld.installazione.istruzioni3": "'Aggiungi a Home'",
  "ld.installazione.istruzioni4": "per installare la App sul tuo dispositivo",
};

const home = {
  "ld.home.header": "Cosa c'è attorno a te?",
  "ld.home.permissionDenied": "L'utente non ha permesso la geolocalizzazione",
  "ld.home.positionUnavailable": "Impossibile geolocalizzare la posizione del dispositivo",
  "ld.home.timeoutError": "Tempo scaduto",
  "ld.home.unknownError": "Errore sconosciuto",
  "ld.home.browserUnsupported": "Il browser non supporta la geolocalizzazione",
  "ld.home.connessioneAssente": "Nessuna connessione internet presente",
  "ld.home.geolocalizzazioneNegata": "Senza accettare la geolocalizzazione non è possibile visualizzare la mappa",
  "ld.home.dettaglioSpecie": "Vai al dettaglio",
  "ld.home.avvistamenti": "Avvistamenti",
};

const cerca = {
  "ld.cerca.header": "Tra la biodiversità della Liguria",
  "ld.cerca.inserisciComune": "Inserisci un comune",
  "ld.cerca.specieLabel": "Scegli la specie oppure inserisci un nome",
  "ld.cerca.classificazione.flora": "Flora",
  "ld.cerca.classificazione.funghi": "Funghi",
  "ld.cerca.classificazione.mammiferi": "Mammiferi",
  "ld.cerca.classificazione.uccelli": "Uccelli",
  "ld.cerca.classificazione.pesci": "Pesci",
  "ld.cerca.classificazione.anfibi": "Anfibi",
  "ld.cerca.classificazione.rettili": "Rettili",
  "ld.cerca.classificazione.invertebrati": "Invertebrati",
  "ld.cerca.classificazione.tutti": "Tutte le specie",
  "ld.cerca.specieNatura2000": "Specie Natura 2000",
  "ld.cerca.specieAutoctona": "Specie autoctona",
  "ld.cerca.specieAliena": "Specie aliena",
  "ld.cerca.specieAlienaUnionale": "Specie aliena unionale",
  "ld.cerca.zonaZsc": "Zona speciale di conservazione",
  "ld.cerca.zonaZps": "Zona di protezione speciale",
  "ld.cerca.zonaProtetta": "Area protetta",
  "ld.cerca.zonaUmida": "Zona umida",
  "ld.cerca.nomeSpecie": "Nome specie (nome volgare o nome scientifico)",
  "ld.cerca.clear": "Pulisci",
  "ld.cerca.distribuzione": "Distribuzione territoriale",
  "ld.cerca.alert": "Attenzione!",
  "ld.cerca.specieProtettaWarning": "Le informazioni relative a questa specie sono presenti in banca dati ma non possono essere visualizzate poiché si tratta di una specie sensibile e pertanto sono automaticamente escluse dalla visibilità pubblica per ragioni di conservazione", //prettier-ignore
  "ld.cerca.error": "Si è verificato un errore di sistema",
  "ld.cerca.downloadCsv": "Scarica CSV",
};

const dettaglio = {
  "ld.dettaglio.header": "Specie osservate",
  "ld.dettaglio.dettaglioDistribuzione": "Osservazioni sul territorio",
  "ld.dettaglio.codiceSpecie": "Specie:",
  "ld.dettaglio.nomeScientifico": "Nome scientifico:",
  "ld.dettaglio.sinonimi": "Sinonimi",
  "ld.dettaglio.idStazione": "Identificativo stazione:",
  "ld.dettaglio.regno": "Regno:",
  "ld.dettaglio.classe": "Classe:",
  "ld.dettaglio.listaRossa": "Lista rossa italiana:",
  "ld.dettaglio.normative": "Normative:",
  "ld.dettaglio.famiglia": "Famiglia:",
  "ld.dettaglio.genere": "Genere:",
  "ld.dettaglio.ordine": "Ordine:",
  "ld.dettaglio.nomeVolgare": "Nome volgare:",
  "ld.dettaglio.categoria": "Categoria:",
  "ld.dettaglio.specieAliena": "Specie aliena per la Liguria",
  "ld.dettaglio.specieAlienaUnionale": "Specie aliena unionale",
  "ld.dettaglio.comune": "Comune:",
  "ld.dettaglio.immagini": "Immagini:",
  "ld.dettaglio.noData": "Nessun dettaglio per la specie selezionata",
  "ld.dettaglio.specieLabel": "Scegli la specie",
};

const segnala = {
  "ld.segnala.header": "Compila e invia la segnalazione",
  "ld.segnala.inserisciDataSegnalazione": "Inserisci una data!",
  "ld.segnala.dataSegnalazione": "Data (GG/MM/AAAA)",
  "ld.segnala.selezionaEnteRiferimento": "Seleziona un ente di riferimento!",
  "ld.segnala.enteRiferimento": "Ente di riferimento",
  "ld.segnala.selezionaTipoOsservazione": "Seleziona un tipo di osservazione!",
  "ld.segnala.tipoOsservazione": "Tipo di osservazione",
  "ld.segnala.inserisciNominativo": "Inserisci un nome e un cognome!",
  "ld.segnala.nominativo": "Cognome e nome",
  "ld.segnala.inserisciImmagini": "Carica almeno un'immagine!",
  "ld.segnala.upload": "Carica immagini (Max. 3)",
  "ld.segnala.tipoProvenienza": "Tipo provenienza",
  "ld.segnala.selezionaTipoProvenienza": "Seleziona un tipo provenienza",
  "ld.segnala.selezionaSpecie": "Seleziona una specie!",
  "ld.segnala.nomeSpecie": "Nome specie",
  "ld.segnala.specieSconosciuta": "Specie sconosciuta?",
  "ld.segnala.descrizione": "Descrizione",
  "ld.segnala.selezionaClasseIspra": "Seleziona una classe ISPRA!",
  "ld.segnala.classeIspra": "Classe ISPRA",
  "ld.segnala.inserisciNumeroIndividui": "Associa un numero di individui!",
  "ld.segnala.numeroIndividui": "Numero di individui effettivamente conteggiati",
  "ld.segnala.inserisciUnitaPopolazione": "Associa un'unità di popolazione!",
  "ld.segnala.unitaPopolazione": "Unità di popolazione (se diversa dal numero di individui)",
  "ld.segnala.inserisciValoreUnitaPopolazione": "Associa un valore di unità di popolazione!",
  "ld.segnala.valoreUnitaPopolazione": "Valore unità di popolazione",
  "ld.segnala.selezionaTarget": "Seleziona un target!",
  "ld.segnala.target": "Target",
  "ld.segnala.inserisciNote": "Associa delle note!",
  "ld.segnala.note": "Note",
  "ld.segnala.selezionaTecnicheRilevamento": "Seleziona una tecnica di rilevamento!",
  "ld.segnala.tecnicheRilevamento": "Tecniche di rilevamento",
  "ld.segnala.selezionaEntitaRilevata": "Seleziona un'entità rilevata!",
  "ld.segnala.entitaRilevata": "Entità rilevata",
  "ld.segnala.selezionaSottogruppoMonitorato": "Seleziona un sotto gruppo monitorato!",
  "ld.segnala.sottogruppoMonitorato": "Sotto gruppo monitorato",
  "ld.segnala.selezionaStadioSviluppo": "Seleziona uno stadio di sviluppo!",
  "ld.segnala.stadioSviluppo": "Stadio di sviluppo",
  "ld.segnala.selezionaAbitudini": "Seleziona un'abitudine!",
  "ld.segnala.abitudini": "Abitudini",
  "ld.segnala.inserisciPosizione": "Associa una posizione!",
  "ld.segnala.rilevaPosizione": "Rileva posizione",
  "ld.segnala.modificaPosizione": "Modifica posizione",
  "ld.segnala.inserisciLatitudine": "Associa una latitudine!",
  "ld.segnala.latitudine": "Latitudine",
  "ld.segnala.inserisciLongitudine": "Associa una longitudine!",
  "ld.segnala.longitudine": "Longitudine",
  "ld.segnala.pulisciDati": "Pulisci",
  "ld.segnala.salvaSegnalazione": "Salva",
  "ld.segnala.inviaSegnalazione": "Invia",
  "ld.segnala.warningPositionTitle": "Attenzione!",
  "ld.segnala.warningPositionContent": "La posizione selezionata risulta essere esterna ai confini regionali. Si vuole continuare?", //prettier-ignore
  "ld.segnala.warningPositionContinue": "Continua",
  "ld.segnala.warningPositionUndo": "Riposiziona",
  "ld.segnala.segnalazioneInviata": "Segnalazione inviata correttamente",
  "ld.segnala.segnalazioneSalvata": "Segnalazione salvata correttamente",
  "ld.segnala.segnalazioneErrore": "Si è verificato un errore nell'invio della segnalazione",
  "ld.segnala.sottotitoloSegnalazioneErrore": "Puoi trovare la tua segnalazione all'interno della tua area personale e reinviarla in qualsiasi momento", //prettier-ignore
  "ld.segnala.linkToSegnalazioni": "Vai alle tue segnalazioni",
  "ld.segnala.linkToHome": "Torna alla home page",
  "ld.segnala.uploadNonConcessoTitle": "Formato non ammesso",
  "ld.segnala.uploadNonConcessoDescr": "I formati ammessi sono .jpeg e .png",
  "ld.segnala.alert": "Attenzione!",
  "ld.segnala.quotaexceeded": "Hai superato il limite delle dimensioni delle immagini sulle segnalazioni salvate ma non inviate. Procedi all’invio per poter salvare la nuova immagine.", //prettier-ignore
  "ld.segnala.nuovaSegnalazione": "Nuova segnalazione",
};

const mappaSpecieAliene = {
  "ld.mappaSpecieAliene.header": "Mappa densità delle specie aliene",
};

const segnalazioni = {
  "ld.segnalazioni.header": "Le mie segnalazioni",
  "ld.segnalazioni.datetimeSegnalazione": "Data e ora",
  "ld.segnalazioni.datetimeSegnalazioneShort": "Data",
  "ld.segnalazioni.titoloSegnalazione": "Soggetto",
  "ld.segnalazioni.titoloSegnalazioneShort": "Sogg.",
  "ld.segnalazioni.search": "Cerca",
  "ld.segnalazioni.reset": "Reset",
  "ld.segnalazioni.segnalazioneInviata": "Inviata",
  "ld.segnalazioni.segnalazioneInviataShort": "Inv.",
  "ld.segnalazioni.segnalazioneInviataYes": "Inviata",
  "ld.segnalazioni.segnalazioneInviataNo": "Non inviata",
  "ld.segnalazioni.segnalazioneApprovata": "Approvata",
  "ld.segnalazioni.segnalazioneApprovataShort": "App.",
  "ld.segnalazioni.segnalazioneInAttesaDiApprovazione": "In attesa di approvazione",
  "ld.segnalazioni.segnalazioneApprovataYes": "Approvata",
  "ld.segnalazioni.segnalazioneApprovataNo": "Non approvata",
  "ld.segnalazioni.confermaEliminazione": "Sei sicuro di voler rimuovere la seguente segnalazione dal tuo dispostivo?",
  "ld.segnalazioni.inviaSegnalazioni": "Inviami la lista",
};

const visualizzaSegnalazione = {
  "ld.visualizzaSegnalazione.header": "La tua segnalazione",
  "ld.visualizzaSegnalazione.backToSegnalazioni": "Torna alle tue segnalazioni",
  "ld.visualizzaSegnalazione.dataSegnalazione": "Data",
  "ld.visualizzaSegnalazione.immaginiSegnalazione": "Immagini",
  "ld.visualizzaSegnalazione.erroreRecupero": "Errore durante il recupero della segnalazione",
};

const landingPage = {
  "ld.landingPage.erroreRecupero": "Errore nel recupero dei dati utente",
};

const credits = {
  "ld.credits.header": "Partner e crediti",
};

const privacy = {
  "ld.privacy.header": "Informativa sulla privacy",
};

const it = {
  ...common,
  ...installazione,
  ...home,
  ...cerca,
  ...dettaglio,
  ...segnala,
  ...mappaSpecieAliene,
  ...segnalazioni,
  ...visualizzaSegnalazione,
  ...landingPage,
  ...credits,
  ...privacy,
};
export { it };
