import update from "immutability-helper";
import {
  IS_NETWORK_CONNECTED,
  FETCH_COMUNI_SUCCESS,
  FETCH_CLASSIFICAZIONI_SUCCESS,
  FETCH_TIPI_PROVENIENZA_SUCCESS,
  FETCH_SPECIE_SUCCESS,
  FETCH_SPECIETOTALI_SUCCESS,
  FETCH_CLASSI_ISPRA_SUCCESS,
  FETCH_LISTA_UNITA_POPOLAZIONE_SUCCESS,
  FETCH_ENTITA_RILEVATE_SUCCESS,
  FETCH_TARGET_SUCCESS,
  FETCH_SOTTOGRUPPI_SUCCESS,
  FETCH_SVILUPPI_SUCCESS,
  FETCH_ABITUDINI_SUCCESS,
  SET_POSITION,
  SHOW_APPLE_INSTALLATION_INFO,
  REHYDRATE_WITH_SESSION_STORAGE,
} from "./index";

export const initialState = {
  networkConnected: window.navigator.onLine,
  comuni: {
    data: null,
    fetchEnded: false,
  },
  classificazioni: {
    data: null,
    fetchEnded: false,
  },
  tipiProvenienza: {
    data: null,
    fetchEnded: false,
  },
  specie: {
    data: null,
    fetchEnded: false,
  },
  specietotali: {
    data: null,
    fetchEnded: false,
  },
  classiIspra: {
    data: null,
    fetchEnded: false,
  },
  listaUnitaPopolazione: {
    data: null,
    fetchEnded: false,
  },
  entitaRilevate: {
    data: null,
    fetchEnded: false,
  },
  target: {
    data: null,
    fetchEnded: false,
  },
  sottogruppi: {
    data: null,
    fetchEnded: false,
  },
  sviluppi: {
    data: null,
    fetchEnded: false,
  },
  abitudini: {
    data: null,
    fetchEnded: false,
  },
  position: {
    latitude: null,
    longitude: null,
    positionSetted: false,
  },
  showAppleInstallationDrawer: null,
};

const networkConnectedHandler = (state, action) => {
  return update(state, {
    networkConnected: { $set: action.isConnected },
  });
};

const fetchComuniSuccessHandler = (state, action) => {
  return update(state, {
    comuni: {
      data: { $set: action.comuni },
      fetchEnded: { $set: true },
    },
  });
};

const fetchClassificazioniSuccessHandler = (state, action) => {
  return update(state, {
    classificazioni: {
      data: { $set: action.classificazioni },
      fetchEnded: { $set: true },
    },
  });
};

const fetchTipiProvenienzaSuccessHandler = (state, action) => {
  return update(state, {
    tipiProvenienza: {
      data: { $set: action.tipiProvenienza },
      fetchEnded: { $set: true },
    },
  });
};

const fetchSpecieSuccessHandler = (state, action) => {
  return update(state, {
    specie: {
      data: { $set: action.specie },
      fetchEnded: { $set: true },
    },
  });
};

const fetchSpecieTotaliSuccessHandler = (state, action) => {
  return update(state, {
    specietotali: {
      data: { $set: action.specietotali },
      fetchEnded: { $set: true },
    },
  });
};

const fetchClassiIspraSuccessHandler = (state, action) => {
  return update(state, {
    classiIspra: {
      data: { $set: action.classiIspra },
      fetchEnded: { $set: true },
    },
  });
};

const fetchListaUnitaPopolazioneSuccessHandler = (state, action) => {
  return update(state, {
    listaUnitaPopolazione: {
      data: { $set: action.listaUnitaPopolazione },
      fetchEnded: { $set: true },
    },
  });
};

const fetchEntitaRilevateSuccessHandler = (state, action) => {
  return update(state, {
    entitaRilevate: {
      data: { $set: action.entitaRilevate },
      fetchEnded: { $set: true },
    },
  });
};

const fetchTargetSuccessHandler = (state, action) => {
  return update(state, {
    target: {
      data: { $set: action.target },
      fetchEnded: { $set: true },
    },
  });
};

const fetchSottogruppiSuccessHandler = (state, action) => {
  return update(state, {
    sottogruppi: {
      data: { $set: action.sottogruppi },
      fetchEnded: { $set: true },
    },
  });
};

const fetchSviluppiSuccessHandler = (state, action) => {
  return update(state, {
    sviluppi: {
      data: { $set: action.sviluppi },
      fetchEnded: { $set: true },
    },
  });
};

const fetchAbitudiniSuccessHandler = (state, action) => {
  return update(state, {
    abitudini: {
      data: { $set: action.abitudini },
      fetchEnded: { $set: true },
    },
  });
};

const setPositionHandler = (state, action) => {
  return update(state, {
    position: {
      latitude: { $set: action.latitude },
      longitude: { $set: action.longitude },
      positionSetted: { $set: true },
    },
  });
};

const showAppleInstallationInfoHandler = (state, action) => {
  return update(state, {
    showAppleInstallationDrawer: { $set: action.value },
  });
};

const rehydrateWithSessionStorageHandler = (state, action) => {
  return update(state, { $set: action.sessionStorageValues });
};

export const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_NETWORK_CONNECTED:
      return networkConnectedHandler(state, action);
    case FETCH_COMUNI_SUCCESS:
      return fetchComuniSuccessHandler(state, action);
    case FETCH_CLASSIFICAZIONI_SUCCESS:
      return fetchClassificazioniSuccessHandler(state, action);
    case FETCH_TIPI_PROVENIENZA_SUCCESS:
      return fetchTipiProvenienzaSuccessHandler(state, action);
    case FETCH_SPECIE_SUCCESS:
      return fetchSpecieSuccessHandler(state, action);
    case FETCH_SPECIETOTALI_SUCCESS:
      return fetchSpecieTotaliSuccessHandler(state, action);  
    case FETCH_CLASSI_ISPRA_SUCCESS:
      return fetchClassiIspraSuccessHandler(state, action);
    case FETCH_LISTA_UNITA_POPOLAZIONE_SUCCESS:
      return fetchListaUnitaPopolazioneSuccessHandler(state, action);
    case FETCH_ENTITA_RILEVATE_SUCCESS:
      return fetchEntitaRilevateSuccessHandler(state, action);
    case FETCH_TARGET_SUCCESS:
      return fetchTargetSuccessHandler(state, action);
    case FETCH_SOTTOGRUPPI_SUCCESS:
      return fetchSottogruppiSuccessHandler(state, action);
    case FETCH_SVILUPPI_SUCCESS:
      return fetchSviluppiSuccessHandler(state, action);
    case FETCH_ABITUDINI_SUCCESS:
      return fetchAbitudiniSuccessHandler(state, action);
    case SET_POSITION:
      return setPositionHandler(state, action);
    case SHOW_APPLE_INSTALLATION_INFO:
      return showAppleInstallationInfoHandler(state, action);
    case REHYDRATE_WITH_SESSION_STORAGE:
      return rehydrateWithSessionStorageHandler(state, action);
    default:
      return state;
  }
};
