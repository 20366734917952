import Icon from "@ant-design/icons";

const ApplePlusIcon = () => (
  <svg viewBox="0 0 76.32 79.55">
    <rect className="cls-1apple" x="2.5" y="2.5" width="71.32" height="74.55" rx="5.28" />
    <rect className="cls-2apple" x="35.35" y="19.01" width="8" height="44.26" />
    <rect className="cls-2apple" x="35.35" y="19.35" width="8" height="44.26" transform="translate(-2.13 80.83) rotate(-90)" />
  </svg>
);

export const ApplePlus = (props) => <Icon component={ApplePlusIcon} {...props} />;
