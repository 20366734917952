import Icon from "@ant-design/icons";

export const MappaIcon = () => (
  <svg
    id="uuid-30eb0159-27d5-44f4-83cc-d0b35f8445a9"
    aria-hidden="true"
    aria-label="Icona mappa"
    focusable="false"
    data-prefix="fas"
    data-icon="map"
    className="svg-inline--fa fa-map fa-w-16"
    role="img"
    viewBox="0 0 350.49 334.69"
  >
    <g>
      <path
        fill="currentColor"
        d="m246.87,217.74l37.86-61.79c1.96-3.2,3.97-6.44,5.94-9.68,1-1.6,1.96-3.2,2.97-4.79.46-.78.87-1.55,1.32-2.33.96-1.74,1.96-3.52,3.24-5.11l.82-1.28c2.65-4.25,5.43-8.63,7.81-13.06,8.86-16.39,11.69-35.8,8.04-54.66-1.74-7.9-4.34-15.25-7.81-21.74C293.13,16.99,264.77,0,234.86,0c-2.19,0-4.43.09-6.67.27-32.47,2.74-60.74,25.39-70.42,56.35-5.53,17.86-4.93,37.13,1.74,54.34,2.79,7.12,6.9,13.75,10.91,20.14l.96,1.6c2.28,2.28,4.2,5.8,5.85,9.04.73,1.42,1.42,2.74,2.06,3.74l32.29,52.65,17.12,27.95c.32.55.68,1.19,1.05,1.87.64,1.14,1.32,2.42,2.1,3.52,1.05,1.1,2.06,1.83,3.11,1.96,1.23-.05,2.56-1.05,3.84-2.47.55-.78,1.05-1.64,1.55-2.51.27-.55.59-1.05.91-1.55l5.62-9.18Zm-40.64-121.43c-6.21-12.19-3.79-27.17,6.07-37.31,6.26-6.03,14.66-9.5,23.2-9.5,5.11,0,10,1.19,14.48,3.56,12.56,6.53,19.59,20.69,17.12,34.43-2.51,13.93-14.02,24.84-27.95,26.49-1.28.18-2.56.23-3.79.23-12.19,0-23.61-7.03-29.14-17.9Z"
      />
      <path
        fill="currentColor"
        d="m307.15,139.92c-.09.05-.27.09-.41.14-.91,2.56-2.92,5.34-4.7,7.81-.82,1.14-1.6,2.19-2.19,3.11l-22.19,36.26-27.4,44.62c-.18.32-.37.64-.55.91l-.64,1.14c-1.37,2.33-2.83,4.79-4.93,6.62-1.05.96-2.28,1.69-3.61,2.19v91.97l109.96-38.63V124.67l-43.34,15.25Z"
      />
      <path
        fill="currentColor"
        d="m227.19,241.25c-1.87-1.37-3.2-3.2-4.38-5.07-1.14-1.64-2.1-3.33-3.06-4.93-.46-.82-.96-1.64-1.42-2.47l-37.45-61.01c-2.79-4.52-5.57-9.09-8.36-13.61-1.87-3.06-3.79-6.12-5.75-9.18-1.05-1.55-2.06-3.2-3.06-4.79-.91-.32-1.83-.73-2.6-1.1-.46-.23-.82-.37-1-.46l-39.87-13.97v171.39l110.01,38.63v-91.83s-.09-.09-.18-.09c-1-.41-1.96-.91-2.88-1.51Z"
      />
      <polygon fill="currentColor" points="0 334.69 109.96 296.05 109.96 124.67 0 163.3 0 334.69" />
    </g>
  </svg>
);

export const Mappa = (props) => <Icon component={MappaIcon} {...props} />;
