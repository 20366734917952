export const CITTADINO = "001";
export const OPERATORE = "002";

export const DATA = "dataSegnalazione";
export const DATA_OSSERVAZIONE = "dataOss";
export const NOMINATIVO = "nomeCognomeSegnalazione";
export const ENTE_RIFERIMENTO = "enteRiferimento";
export const CODICE_RILEVATORE = "codRilevatore";
export const IMMAGINI = "immaginiSegnalazione";
export const DESCRIZIONE = "descrSegnalazione";
export const LATITUDINE = "latitudineSegnalazione";
export const LONGITUDINE = "longitudineSegnalazione";
export const TIPO_PROVENIENZA = "tipoProv";
export const NOME_SPECIE = "nomeSpecie";
export const CLASSE_ISPRA = "classeIspra";
export const NUMERO_INDIVIDUI = "numeroIndividui";
export const UNITA_POPOLAZIONE = "unitaPopolazione";
export const VALORE_UNITA_POPOLAZIONE = "valoreUnitaPopolazione";
export const TARGET = "target";
export const NOTE = "note";
export const ENTITA_RILEVATA = "entitaRilevata";
export const SOTTOGRUPPO_MONITORATO = "sottogruppoMonitorato";
export const STADIO_SVILUPPO = "stadioSviluppo";
export const ABITUDINI = "abitudini";
export const POSIZIONE = "posizioneSegnalazione";

export const ENTE_RIFERIMENTO_SERVIZIO = "codEnteDet";
export const CODICE_RILEVATORE_SERVIZIO = "codRilevDet";
export const IMMAGINI_SERVIZIO = "listaImmagini";
export const NOME_SPECIE_SERVIZIO = "codSpecie";
export const CLASSE_ISPRA_SERVIZIO = "codClasseIspra";
export const NUMERO_INDIVIDUI_SERVIZIO = "numSpecie";
export const UNITA_POPOLAZIONE_SERVIZIO = "codPopulationNumber";
export const VALORE_UNITA_POPOLAZIONE_SERVIZIO = "numPopulationNumber";
export const TARGET_SERVIZIO = "tipoTarget";
export const NOTE_SERVIZIO = "annote";
export const ENTITA_RILEVATA_SERVIZIO = "tipoMonit";
export const SOTTOGRUPPO_MONITORATO_SERVIZIO = "tipoMonitSub";
export const STADIO_SVILUPPO_SERVIZIO = "tipoStadioSvil";
export const ABITUDINI_SERVIZIO = "tipoAbitudini";
export const LONGITUDINE_SERVIZIO = "coordXWgs84";
export const LATITUDINE_SERVIZIO = "coordYWgs84";

export const SAVING = "saving";
export const SAVED = "saved";
export const SENT = "sent";
export const FAILED = "failed";

export const TITOLO_SEGNALAZIONE = "titolo";
export const SEGNALAZIONE_INVIATA = "segnalazioneInviata";
export const SEGNALAZIONE_APPROVATA = "segnalazioneApprovata";
export const SEGNALAZIONE_APPROVATA_SERVIZIO = "flagPubblicato";
