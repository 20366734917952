import Icon from "@ant-design/icons";

const SegnalaIcon = () => (
  <svg
    aria-hidden="true"
    aria-label="Icona segnala"
    focusable="false"
    data-prefix="fas"
    data-icon="binoculars"
    className="svg-inline--fa fa-binoculars fa-w-16"
    role="img"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M416 48c0-8.84-7.16-16-16-16h-64c-8.84 0-16 7.16-16 16v48h96V48zM63.91 159.99C61.4 253.84 3.46 274.22 0 404v44c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32V288h32V128H95.84c-17.63 0-31.45 14.37-31.93 31.99zm384.18 0c-.48-17.62-14.3-31.99-31.93-31.99H320v160h32v160c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-44c-3.46-129.78-61.4-150.16-63.91-244.01zM176 32h-64c-8.84 0-16 7.16-16 16v48h96V48c0-8.84-7.16-16-16-16zm48 256h64V128h-64v160z"
    ></path>
  </svg>
);

export const Segnala = (props) => <Icon component={SegnalaIcon} {...props} />;
