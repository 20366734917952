import Icon from "@ant-design/icons";

const UserPrimarioIcon = () => (
  <svg viewBox="0 0 90.71 90.71">
    <g>
      <g>
        <circle className="cls-primario" cx="45.36" cy="45.36" r="45.11" />
        <path className="cls-bianco" d="M58.62,35.62A13.27,13.27,0,1,1,45.36,22.35,13.26,13.26,0,0,1,58.62,35.62Z" />
        <path className="cls-bianco" d="M58.61,65.88a13.26,13.26,0,0,0-26.51,0" />
      </g>
    </g>
  </svg>
);

export const UserPrimario = (props) => <Icon component={UserPrimarioIcon} {...props} />;
