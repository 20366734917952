import { createSelector } from "reselect";

const getLibiossStore = (state) => state.libioss;

export const getEntita = createSelector(getLibiossStore, (libioss) => libioss.dettaglio.data);
export const fetchEntitaEnded = createSelector(getLibiossStore, (libioss) => libioss.dettaglio.fetchEnded);

export const getListaSegnalazioni = createSelector(getLibiossStore, (libioss) => libioss.listaSegnalazioni.data);
export const fetchListaSegnalazioniEnded = createSelector(getLibiossStore, (libioss) => libioss.listaSegnalazioni.fetchEnded); //prettier-ignore

export const getInfoLogin =  createSelector(getLibiossStore, (libioss) => libioss.infoLogin.data); //prettier-ignore
export const fetchInfoLoginEnded = createSelector(getLibiossStore, (libioss) => libioss.infoLogin.fetchEnded); //prettier-ignore

export const getSegnalazione = createSelector(getLibiossStore, (libioss) => libioss.segnalazione);
