import { all, put, takeLatest } from "redux-saga/effects";
import { Utility } from "ld_core";
import { it, en } from "../../intl";
const { FETCH_MESSAGES, fetchMessagesSuccess, languages } = Utility.configUtils;

const { IT, EN } = languages;

function* fetchMessagesWorker(action) {
  const locale = action.locale;
  try {
    if (locale === IT) yield put(fetchMessagesSuccess(locale, it));
    if (locale === EN) yield put(fetchMessagesSuccess(locale, en));
  } catch (e) {
    console.error("Errore durante il caricamento del dizionario:", e);
  }
}

function* fetchMessagesWatcher() {
  yield takeLatest(FETCH_MESSAGES, fetchMessagesWorker);
}

export function* languageWatchers() {
  yield all([fetchMessagesWatcher()]);
}
