import React, { useContext } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { LDCoreContext } from "ld_core";
import { SwitchContent } from "./SwitchContent";
import { AppHeader } from "../components/AppHeader";
import { AppFooter } from "../components/AppFooter";

const BASENAME = "/applibioss";

export const App = () => {
  const { store, persistor, messages, locale, defaultLocale } = useContext(LDCoreContext);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <IntlProvider messages={messages} locale={locale} defaultLocale={defaultLocale}>
          <Router basename={BASENAME}>
            <AppHeader />
            <SwitchContent />
            <AppFooter />
          </Router>
        </IntlProvider>
      </PersistGate>
    </Provider>
  );
};
