import {
  FETCH_ENTITA,
  FETCH_ENTITA_SUCCESS,
  FETCH_IMMAGINE_ENTITA,
  FETCH_IMMAGINE_ENTITA_SUCCESS,
  RESET_ENTITA,
  FETCH_LISTA_SEGNALAZIONI,
  FETCH_LISTA_SEGNALAZIONI_SUCCESS,
  FETCH_INFO_LOGIN,
  FETCH_INFO_LOGIN_SUCCESS,
  RESET_INFO_LOGIN,
  SET_FIELD_SEGNALAZIONE,
  RESET_SEGNALAZIONE,
  SALVA_SEGNALAZIONE,
  INVIA_SEGNALAZIONE,
  INVIA_SEGNALAZIONE_SUCCESS,
  RECUPERA_SEGNALAZIONE,
  RIMUOVI_SEGNALAZIONE,
  DOWNLOAD_CSV,
} from "./index";

export const fetchEntita = (codSpecie) => ({
  type: FETCH_ENTITA,
  codSpecie,
});

export const fetchEntitaSuccess = (entita) => ({
  type: FETCH_ENTITA_SUCCESS,
  entita,
});

export const fetchImmagineEntita = (nomeImmagine) => ({
  type: FETCH_IMMAGINE_ENTITA,
  nomeImmagine,
});

export const fetchImmagineEntitaSuccess = (immagine) => ({
  type: FETCH_IMMAGINE_ENTITA_SUCCESS,
  immagine,
});
export const resetEntita = () => ({
  type: RESET_ENTITA,
});

export const fetchListaSegnalazioni = (codTipoUtente) => ({
  type: FETCH_LISTA_SEGNALAZIONI,
  codTipoUtente,
});

export const fetchListaSegnalazioniSuccess = (listaSegnalazioni) => ({
  type: FETCH_LISTA_SEGNALAZIONI_SUCCESS,
  listaSegnalazioni,
});

export const fetchInfoLogin = () => ({
  type: FETCH_INFO_LOGIN,
});

export const fetchInfoLoginSuccess = (infoLogin) => ({
  type: FETCH_INFO_LOGIN_SUCCESS,
  infoLogin,
});

export const resetInfoLogin = () => ({
  type: RESET_INFO_LOGIN,
});

export const setFieldSegnalazione = (field, value) => ({
  type: SET_FIELD_SEGNALAZIONE,
  field,
  value,
});

export const resetSegnalazione = () => ({
  type: RESET_SEGNALAZIONE,
});

export const salvaSegnalazione = (segnalazione, isOperatore) => ({
  type: SALVA_SEGNALAZIONE,
  segnalazione,
  isOperatore,
});

export const inviaSegnalazione = (values, codTipoUtente) => ({
  type: INVIA_SEGNALAZIONE,
  values,
  codTipoUtente,
});

export const inviaSegnalazioneSuccess = (esito, isOperatore) => ({
  type: INVIA_SEGNALAZIONE_SUCCESS,
  esito,
  isOperatore,
});

export const recuperaSegnalazione = (segnalazione) => ({
  type: RECUPERA_SEGNALAZIONE,
  segnalazione,
});

export const rimuoviSegnalazione = (tmpId, isOperatore) => ({
  type: RIMUOVI_SEGNALAZIONE,
  tmpId,
  isOperatore,
});

export const downloadCsv = (listaSpecie) => ({
  type: DOWNLOAD_CSV,
  listaSpecie,
});
