import { TIPO_PROVENIENZA } from "./constants";
import {
  DATA,
  DATA_OSSERVAZIONE,
  ENTE_RIFERIMENTO,
  IMMAGINI,
  NOME_SPECIE,
  DESCRIZIONE,
  CLASSE_ISPRA,
  NUMERO_INDIVIDUI,
  UNITA_POPOLAZIONE,
  VALORE_UNITA_POPOLAZIONE,
  TARGET,
  NOTE,
  ENTITA_RILEVATA,
  SOTTOGRUPPO_MONITORATO,
  STADIO_SVILUPPO,
  ABITUDINI,
  POSIZIONE,
  LATITUDINE,
  LONGITUDINE,
  ENTE_RIFERIMENTO_SERVIZIO,
  IMMAGINI_SERVIZIO,
  NOME_SPECIE_SERVIZIO,
  TITOLO_SEGNALAZIONE,
  CLASSE_ISPRA_SERVIZIO,
  NUMERO_INDIVIDUI_SERVIZIO,
  UNITA_POPOLAZIONE_SERVIZIO,
  VALORE_UNITA_POPOLAZIONE_SERVIZIO,
  TARGET_SERVIZIO,
  NOTE_SERVIZIO,
  ENTITA_RILEVATA_SERVIZIO,
  SOTTOGRUPPO_MONITORATO_SERVIZIO,
  STADIO_SVILUPPO_SERVIZIO,
  ABITUDINI_SERVIZIO,
  LATITUDINE_SERVIZIO,
  LONGITUDINE_SERVIZIO,
  SEGNALAZIONE_INVIATA,
  SEGNALAZIONE_APPROVATA,
  SEGNALAZIONE_APPROVATA_SERVIZIO,
  CITTADINO,
  OPERATORE,
  CODICE_RILEVATORE,
  CODICE_RILEVATORE_SERVIZIO,
} from "./index";

export const preparaListaEntiRiferimento = (data) => {
  let entiRiferimento = [];
  if (data && data !== undefined) {
    entiRiferimento = data.map((ente) => ({
      id: ente.codEnte.toString(),
      value: ente.descrEnte,
      codRilevatore: ente.codRilev,
    }));
  }
  return entiRiferimento;
};

export const preparaDettaglio = (data) => {
  let dettaglio = null;
  if (data && data !== undefined)
    dettaglio = {
      id: data.codSpecie.toString(),
      nomeScientifico: data.descrSpecie,
      ...(data.codStaz !== undefined && { idStazione: data.codStaz.toString() }),
      ...(data.descRegno !== undefined && { regno: data.descRegno }),
      ...(data.descrClasse !== undefined && { classe: data.descrClasse }),
      ...(data.descrFamiglia !== undefined && { famiglia: data.descrFamiglia }),
      ...(data.descrGenere !== undefined && { genere: data.descrGenere }),
      ...(data.descrOrdine !== undefined && { ordine: data.descrOrdine }),
      ...(data.nomiVulgo !== undefined && { nomeComune: data.nomiVulgo.split(",") }),
      ...(data.codCategoriaWs !== undefined && { categoria: data.codCategoriaWs.toString() }),
      ...(data.flagRiservato !== undefined && { isSensibile: data.flagRiservato === "NO" ? false : true }),
      ...(data.flagSpecieAliena !== undefined && { isAliena: data.flagSpecieAliena === "NO" ? false : true }),
      ...(data.flagAlienaUnionale !== undefined && { isAlienaUnionale: data.flagAlienaUnionale === "NO" ? false : true }),
      ...(data.codComune !== undefined && { idComune: data.codComune.toString() }),
      ...(data.nomiImg !== undefined && { immagini: data.nomiImg.split(",") }),
      ...(data.sinonimi !== undefined && { sinonimi: data.sinonimi }),
      ...(data.descrListaRossa !== undefined && { listaRossa: data.descrListaRossa }),
      ...(data.norme !== undefined && { normative: data.norme.split("#") }),
    };
  return dettaglio;
};

const preparaSegnalazioniServizio = (data, listaSpecie) => {
  let listaSegnalazioni = [];
  if (data && data !== undefined) {
    listaSegnalazioni = data.map((segnalazione) => ({
      key: segnalazione.idSegnalazione.toString(),
      [DATA]: segnalazione[DATA],
      [DATA_OSSERVAZIONE]: segnalazione[DATA_OSSERVAZIONE],
      ...(segnalazione[ENTE_RIFERIMENTO_SERVIZIO] && segnalazione[ENTE_RIFERIMENTO_SERVIZIO] !== undefined && { [ENTE_RIFERIMENTO]: [segnalazione[ENTE_RIFERIMENTO_SERVIZIO].toString()] }), //prettier-ignore
      [NOME_SPECIE]: [segnalazione[NOME_SPECIE_SERVIZIO].toString()],
      [TITOLO_SEGNALAZIONE]: segnalazione[DESCRIZIONE] && segnalazione[DESCRIZIONE] !== undefined ? segnalazione[DESCRIZIONE]: listaSpecie.find((specie) => specie.id === segnalazione[NOME_SPECIE_SERVIZIO].toString()).value, //prettier-ignore
      ...(segnalazione[CLASSE_ISPRA_SERVIZIO] && segnalazione[CLASSE_ISPRA_SERVIZIO] !== undefined && { [CLASSE_ISPRA]: [segnalazione[CLASSE_ISPRA_SERVIZIO].toString()] }), //prettier-ignore
      ...(segnalazione[NUMERO_INDIVIDUI_SERVIZIO] && segnalazione[NUMERO_INDIVIDUI_SERVIZIO] !== undefined && { [NUMERO_INDIVIDUI]: segnalazione[NUMERO_INDIVIDUI_SERVIZIO] }), //prettier-ignore
      ...(segnalazione[UNITA_POPOLAZIONE_SERVIZIO] && segnalazione[UNITA_POPOLAZIONE_SERVIZIO] !== undefined && { [UNITA_POPOLAZIONE]: [segnalazione[UNITA_POPOLAZIONE_SERVIZIO].toString()] }), //prettier-ignore
      ...(segnalazione[VALORE_UNITA_POPOLAZIONE_SERVIZIO] && segnalazione[VALORE_UNITA_POPOLAZIONE_SERVIZIO] !== undefined && { [VALORE_UNITA_POPOLAZIONE]: segnalazione[VALORE_UNITA_POPOLAZIONE_SERVIZIO] }), //prettier-ignore
      ...(segnalazione[TARGET_SERVIZIO] && segnalazione[TARGET_SERVIZIO] !== undefined && { [TARGET]: [segnalazione[TARGET_SERVIZIO].toString()] }), //prettier-ignore
      ...(segnalazione[NOTE_SERVIZIO] && segnalazione[NOTE_SERVIZIO] !== undefined && { [NOTE]: segnalazione[NOTE_SERVIZIO] }), //prettier-ignore
      ...(segnalazione[ENTITA_RILEVATA_SERVIZIO] && segnalazione[ENTITA_RILEVATA_SERVIZIO] !== undefined && { [ENTITA_RILEVATA]: [segnalazione[ENTITA_RILEVATA_SERVIZIO].toString()] }), //prettier-ignore
      ...(segnalazione[SOTTOGRUPPO_MONITORATO_SERVIZIO] && segnalazione[SOTTOGRUPPO_MONITORATO_SERVIZIO] !== undefined && { [SOTTOGRUPPO_MONITORATO]: [segnalazione[SOTTOGRUPPO_MONITORATO_SERVIZIO].toString()] }), //prettier-ignore
      ...(segnalazione[STADIO_SVILUPPO_SERVIZIO] && segnalazione[STADIO_SVILUPPO_SERVIZIO] !== undefined && { [STADIO_SVILUPPO]: [segnalazione[STADIO_SVILUPPO_SERVIZIO].toString()] }), //prettier-ignore
      ...(segnalazione[ABITUDINI_SERVIZIO] && segnalazione[ABITUDINI_SERVIZIO] !== undefined && { [ABITUDINI]: [segnalazione[ABITUDINI_SERVIZIO].toString()] }), //prettier-ignore
      [POSIZIONE]: {
        [LATITUDINE]: segnalazione[LATITUDINE_SERVIZIO],
        [LONGITUDINE]: segnalazione[LONGITUDINE_SERVIZIO],
      },
      [SEGNALAZIONE_INVIATA]: true,
      [SEGNALAZIONE_APPROVATA]: segnalazione[SEGNALAZIONE_APPROVATA_SERVIZIO]
        ? segnalazione[SEGNALAZIONE_APPROVATA_SERVIZIO] === "NO"
          ? false
          : true
        : null,
    }));
  }
  return listaSegnalazioni;
};

export const preparaListaSegnalazioni = (segnalazioniServizio, listaSpecieTotali, isOperatore) => {
  let storageItem = isOperatore ? "segnalazioniOperatore" : "segnalazioniUtente";
  let listaSegnalazioni = [];
  let segnalazioniInStorage = localStorage.getItem(storageItem) ? JSON.parse(localStorage.getItem(storageItem)) : []; //prettier-ignore
  let segnalazioniLocali = segnalazioniInStorage.map((segnalazione) => {
    segnalazione[TITOLO_SEGNALAZIONE] =
      segnalazione[DESCRIZIONE] !== undefined
        ? segnalazione[DESCRIZIONE]
        : listaSpecieTotali.find((specie) => specie.id === segnalazione[NOME_SPECIE][0]).value;
    segnalazione[SEGNALAZIONE_INVIATA] = false;
    segnalazione[SEGNALAZIONE_APPROVATA] = false;
    segnalazione[DATA_OSSERVAZIONE] = segnalazione[DATA];
    segnalazione.key = segnalazione.tmpId;
    delete segnalazione.status;
    return segnalazione;
  });
  listaSegnalazioni = [...preparaSegnalazioniServizio(segnalazioniServizio, listaSpecieTotali), ...segnalazioniLocali];
  return listaSegnalazioni;
};

export const preparaInfoLogin = (data) => {
  let infoLogin = null;
  if (data && data !== undefined) {
    infoLogin = {
      nominativo: data.nominativo,
      codFiscale: data.codiceFiscale,
      email: data.email,
      telefono: data.numCell,
      codTipoUtente: data.tipoSegnalatore.toUpperCase() === "E" ? OPERATORE : CITTADINO,
      entiRiferimento: preparaListaEntiRiferimento(data.listaEnti),
      ...(data.ruolo !== undefined && { ruolo: data.ruolo }),
    };
  }
  return infoLogin;
};

export const preparaDatiPerSalvataggio = (values) => {
  let postValues = {};
  for (const [key, value] of Object.entries(values)) {
    if (key === ENTE_RIFERIMENTO) postValues[ENTE_RIFERIMENTO_SERVIZIO] = value[0];
    else if (key === DATA) postValues[DATA_OSSERVAZIONE] = value;
    else if (key === CODICE_RILEVATORE) postValues[CODICE_RILEVATORE_SERVIZIO] = value;
    else if (key === IMMAGINI) postValues[IMMAGINI_SERVIZIO] = value;
    else if (key === TIPO_PROVENIENZA) postValues[TIPO_PROVENIENZA] = value[0];
    else if (key === NOME_SPECIE) postValues[NOME_SPECIE_SERVIZIO] = value[0];
    else if (key === CLASSE_ISPRA) postValues[CLASSE_ISPRA_SERVIZIO] = value[0];
    else if (key === NUMERO_INDIVIDUI) postValues[NUMERO_INDIVIDUI_SERVIZIO] = value;
    else if (key === UNITA_POPOLAZIONE) postValues[UNITA_POPOLAZIONE_SERVIZIO] = value[0];
    else if (key === VALORE_UNITA_POPOLAZIONE) postValues[VALORE_UNITA_POPOLAZIONE_SERVIZIO] = value;
    else if (key === TARGET) postValues[TARGET_SERVIZIO] = value[0];
    else if (key === NOTE) postValues[NOTE_SERVIZIO] = value;
    else if (key === ENTITA_RILEVATA) postValues[ENTITA_RILEVATA_SERVIZIO] = value[0];
    else if (key === SOTTOGRUPPO_MONITORATO) postValues[SOTTOGRUPPO_MONITORATO_SERVIZIO] = value[0];
    else if (key === STADIO_SVILUPPO) postValues[STADIO_SVILUPPO_SERVIZIO] = value[0];
    else if (key === ABITUDINI) postValues[ABITUDINI_SERVIZIO] = value[0];
    else if (key === POSIZIONE) {
      postValues[LONGITUDINE_SERVIZIO] = value[LONGITUDINE];
      postValues[LATITUDINE_SERVIZIO] = value[LATITUDINE];
    } else postValues[key] = value;
  }
  return postValues;
};
