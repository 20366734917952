import { all, put, takeLatest } from "redux-saga/effects";
import {
  FETCH_COMUNI,
  FETCH_CLASSIFICAZIONI,
  FETCH_TIPI_PROVENIENZA,
  FETCH_SPECIE,
  FETCH_SPECIETOTALI,
  FETCH_CLASSI_ISPRA,
  FETCH_LISTA_UNITA_POPOLAZIONE,
  FETCH_ENTITA_RILEVATE,
  FETCH_TARGET,
  FETCH_SOTTOGRUPPI,
  FETCH_SVILUPPI,
  FETCH_ABITUDINI,
  fetchComuniSuccess,
  fetchClassificazioniSuccess,
  fetchTipiProvenienzaSuccess,
  fetchSpecieSuccess,
  fetchSpecieTotaliSuccess,
  fetchClassiIspraSuccess,
  fetchListaUnitaPopolazioneSuccess,
  fetchEntitaRilevateSuccess,
  fetchTargetSuccess,
  fetchSottogruppiSuccess,
  fetchSviluppiSuccess,
  fetchAbitudiniSuccess,
  preparaListaComuni,
  preparaListaClassificazioni,
  preparaListaTipiProvenienza,
  preparaListaSpecie,
  preparaListaSpecieTotali,
  preparaClassiIspra,
  preparaListaUnitaPopolazione,
  preparaEntitaRilevate,
  preparaTarget,
  preparaSottogruppi,
  preparaSviluppi,
  preparaAbitudini,
} from "./index";
import { createRequest } from "../../tools";

function* fetchComuniWorker(action) {
  try {
    const response = yield createRequest("GET", "/applibioss/api/liste/comuni");
    if (response.status === 200) {
      const infoFromResponse = preparaListaComuni(response.data);
      if (infoFromResponse) yield put(fetchComuniSuccess(infoFromResponse));
      else throw new Error("Nessun comune presente");
    } else throw response.message;
  } catch (e) {
    console.error("Errore durante il caricamento dei comuni:", e);
    yield put(fetchComuniSuccess([]));
  }
}

function* fetchClassificazioniWorker(action) {
  try {
    const response = yield createRequest("GET", "/applibioss/api/liste/categorie");
    if (response.status === 200) {
      const infoFromResponse = preparaListaClassificazioni(response.data);
      if (infoFromResponse) yield put(fetchClassificazioniSuccess(infoFromResponse));
      else throw new Error("Nessuna classificazione presente");
    } else throw response.message;
  } catch (e) {
    console.error("Errore durante il caricamento delle classificazioni:", e);
    yield put(fetchClassificazioniSuccess([]));
  }
}

function* fetchTipiProvenienzaWorker(action) {
  try {
    const response = yield createRequest("GET", "/applibioss/api/liste/provenienza");
    if (response.status === 200) {
      const infoFromResponse = preparaListaTipiProvenienza(response.data);
      if (infoFromResponse) yield put(fetchTipiProvenienzaSuccess(infoFromResponse));
      else throw new Error("Nessun tipo provenienza presente");
    } else throw response.message;
  } catch (e) {
    console.error("Errore durante il caricamento dei tipi provenienza:", e);
    yield put(fetchTipiProvenienzaSuccess([]));
  }
}

function* fetchSpecieWorker(action) {
  try {
    const response = yield createRequest("GET", "/applibioss/api/specie/lista");
    if (response.status === 200) {
      const infoFromResponse = preparaListaSpecie(response.data);
      if (infoFromResponse) yield put(fetchSpecieSuccess(infoFromResponse));
      else throw new Error("Nessuna specie presente");
    } else throw response.message;
  } catch (e) {
    console.error("Errore durante il caricamento delle specie:", e);
    yield put(fetchSpecieSuccess([]));
  }
}

function* fetchSpecieTotaliWorker(action) {
  try {
    const response = yield createRequest("GET", "/applibioss/api/liste/specie");
    if (response.status === 200) {
      const infoFromResponse = preparaListaSpecieTotali(response.data);
      if (infoFromResponse) yield put(fetchSpecieTotaliSuccess(infoFromResponse));
      else throw new Error("Nessuna specie presente");
    } else throw response.message;
  } catch (e) {
    console.error("Errore durante il caricamento delle specie totali:", e);
    yield put(fetchSpecieTotaliSuccess([]));
  }
}



function* fetchClassiIspraWorker(action) {
  try {
    const response = yield createRequest("GET", "/applibioss/api/liste/class");
    if (response.status === 200) {
      const infoFromResponse = preparaClassiIspra(response.data);
      if (infoFromResponse) yield put(fetchClassiIspraSuccess(infoFromResponse));
      else throw new Error("Nessuna classe Ispra presente");
    } else throw response.message;
  } catch (e) {
    console.error("Errore durante il caricamento delle classi Ispra:", e);
    yield put(fetchClassiIspraSuccess([]));
  }
}

function* fetchListaUnitaPopolazioneWorker(action) {
  try {
    const response = yield createRequest("GET", "/applibioss/api/liste/population");
    if (response.status === 200) {
      const infoFromResponse = preparaListaUnitaPopolazione(response.data);
      if (infoFromResponse) yield put(fetchListaUnitaPopolazioneSuccess(infoFromResponse));
      else throw new Error("Nessuna unità popolazione presente");
    } else throw response.message;
  } catch (e) {
    console.error("Errore durante il caricamento delle unità popolazione:", e);
    yield put(fetchListaUnitaPopolazioneSuccess([]));
  }
}

function* fetchEntitaRilevateWorker(action) {
  try {
    const response = yield createRequest("GET", "/applibioss/api/liste/monit");
    if (response.status === 200) {
      const infoFromResponse = preparaEntitaRilevate(response.data);
      if (infoFromResponse) yield put(fetchEntitaRilevateSuccess(infoFromResponse));
      else throw new Error("Nessuna entità rilevata presente");
    } else throw response.message;
  } catch (e) {
    console.error("Errore durante il caricamento delle entità rilevate:", e);
    yield put(fetchEntitaRilevateSuccess([]));
  }
}

function* fetchTargetWorker(action) {
  try {
    const response = yield createRequest("GET", "/applibioss/api/liste/target");
    if (response.status === 200) {
      const infoFromResponse = preparaTarget(response.data);
      if (infoFromResponse) yield put(fetchTargetSuccess(infoFromResponse));
      else throw new Error("Nessun target presente");
    } else throw response.message;
  } catch (e) {
    console.error("Errore durante il caricamento dei target:", e);
    yield put(fetchTargetSuccess([]));
  }
}

function* fetchSottogruppiWorker(action) {
  try {
    const response = yield createRequest("GET", "/applibioss/api/liste/monitsub");
    if (response.status === 200) {
      const infoFromResponse = preparaSottogruppi(response.data);
      if (infoFromResponse) yield put(fetchSottogruppiSuccess(infoFromResponse));
      else throw new Error("Nessun sottogruppo monitorato presente");
    } else throw response.message;
  } catch (e) {
    console.error("Errore durante il caricamento dei sottogruppi monitorati:", e);
    yield put(fetchSottogruppiSuccess([]));
  }
}

function* fetchSviluppiWorker(action) {
  try {
    const response = yield createRequest("GET", "/applibioss/api/liste/sviluppo");
    if (response.status === 200) {
      const infoFromResponse = preparaSviluppi(response.data);
      if (infoFromResponse) yield put(fetchSviluppiSuccess(infoFromResponse));
      else throw new Error("Nessuno stadio di sviluppo presente");
    } else throw response.message;
  } catch (e) {
    console.error("Errore durante il caricamento degli stadi di sviluppo:", e);
    yield put(fetchSviluppiSuccess([]));
  }
}

function* fetchAbitudiniWorker(action) {
  try {
    const response = yield createRequest("GET", "/applibioss/api/liste/abitudini");
    if (response.status === 200) {
      const infoFromResponse = preparaAbitudini(response.data);
      if (infoFromResponse) yield put(fetchAbitudiniSuccess(infoFromResponse));
      else throw new Error("Nessun'abitudine' presente");
    } else throw response.message;
  } catch (e) {
    console.error("Errore durante il caricamento delle abitudini:", e);
    yield put(fetchAbitudiniSuccess([]));
  }
}

function* fetchComuniWatcher() {
  yield takeLatest(FETCH_COMUNI, fetchComuniWorker);
}

function* fetchClassificazioniWatcher() {
  yield takeLatest(FETCH_CLASSIFICAZIONI, fetchClassificazioniWorker);
}

function* fetchTipiProvenienzaWatcher() {
  yield takeLatest(FETCH_TIPI_PROVENIENZA, fetchTipiProvenienzaWorker);
}

function* fetchSpecieWatcher() {
  yield takeLatest(FETCH_SPECIE, fetchSpecieWorker);
}

function* fetchSpecieTotaliWatcher() {
  yield takeLatest(FETCH_SPECIETOTALI, fetchSpecieTotaliWorker);
}

function* fetchClassiIspraWatcher() {
  yield takeLatest(FETCH_CLASSI_ISPRA, fetchClassiIspraWorker);
}

function* fetchListaUnitaPopolazioneWatcher() {
  yield takeLatest(FETCH_LISTA_UNITA_POPOLAZIONE, fetchListaUnitaPopolazioneWorker);
}

function* fetchEntitaRilevateWatcher() {
  yield takeLatest(FETCH_ENTITA_RILEVATE, fetchEntitaRilevateWorker);
}

function* fetchTargetWatcher() {
  yield takeLatest(FETCH_TARGET, fetchTargetWorker);
}

function* fetchSottogruppiWatcher() {
  yield takeLatest(FETCH_SOTTOGRUPPI, fetchSottogruppiWorker);
}

function* fetchSviluppiWatcher() {
  yield takeLatest(FETCH_SVILUPPI, fetchSviluppiWorker);
}

function* fetchAbitudiniWatcher() {
  yield takeLatest(FETCH_ABITUDINI, fetchAbitudiniWorker);
}

export function* commonWatchers() {
  yield all([
    fetchComuniWatcher(),
    fetchClassificazioniWatcher(),
    fetchTipiProvenienzaWatcher(),
    fetchSpecieWatcher(),
    fetchSpecieTotaliWatcher(),
    fetchClassiIspraWatcher(),
    fetchListaUnitaPopolazioneWatcher(),
    fetchEntitaRilevateWatcher(),
    fetchTargetWatcher(),
    fetchSottogruppiWatcher(),
    fetchSviluppiWatcher(),
    fetchAbitudiniWatcher(),
  ]);
}
