export const preparaListaComuni = (data) => {
  let comuni = null;
  if (data && data !== undefined)
    comuni = data.map((comune) => ({
      id: comune.codCom.toString(),
      value: comune.descrCom,
      provincia: comune.descrProv,
    }));
  return comuni;
};

export const preparaListaClassificazioni = (data) => {
  let listaClassificazioni = null;
  if (data && data !== undefined)
    listaClassificazioni = data.map((classificazione) => ({
      id: classificazione.codCategoriaWs.toString(),
      label: classificazione.nomeCategoriaWs,
    }));
  return listaClassificazioni;
};

export const preparaListaTipiProvenienza = (data) => {
  let listaTipiProvenienza = null;
  if (data && data !== undefined)
    listaTipiProvenienza = data.map((provenienza) => ({
      id: provenienza.tipoProv.toString(),
      value: provenienza.descProv,
    }));
  return listaTipiProvenienza;
};

export const preparaListaSpecie = (data) => {
  let listaSpecie = null;
  if (data && data !== undefined)
    listaSpecie = data.map((specie) => ({
      id: specie.codSpecie.toString(),
      value: specie.descrSpecie,
      nomeComune: specie.nomiVulgo != null ? specie.nomiVulgo : "",
      classe: specie.codCategoriaWs.toString(),
      ...(specie.codCategoriaWs.toString() === "7" && {
        classeTipoInvertebrato: specie.descrClasse.toUpperCase().substr(0, 1) + specie.descrClasse.toLowerCase().substr(1),
      }),
      distribuzione: specie.listaComuni.map((comune) => comune.codComune.toString()),
      isSensibile: specie.flagRiservato === "NO" ? false : true,
      isNatura2000: specie.flagPertNat2000 === "NO" ? false : true,
      isAutoctona: specie.flagSpecieAliena === "NO" ? true : false,
      isAliena: specie.flagSpecieAliena === "NO" ? false : true,
      isAlienaUnionale: specie.flagAlienaUnionale === "NO" ? false : true,
      isZsc: specie.flagSic === "NO" ? false : true,
      isZps: specie.flagZps === "NO" ? false : true,
      isProtetta: specie.flagApr === "NO" ? false : true,
      isUmida: specie.flagZum === "NO" ? false : true,
    }));
  listaSpecie = listaSpecie.sort((a, b) => {
    let nameA = a.value.toUpperCase();
    let nameB = b.value.toUpperCase();
    if (nameA < nameB) return -1;
    else if (nameA > nameB) return 1;
    else return 0;
  });
  return listaSpecie;
};

export const preparaListaSpecieTotali = (data) => {
  let listaSpecieTotali = null;
  if (data && data !== undefined)
    listaSpecieTotali = data.map((specietotali) => ({
      id: specietotali.codSpecie.toString(),
      value: specietotali.descrSpecie,
      isSensibile: specietotali.flagRiservato === "NO" ? false : true,
    }));
  listaSpecieTotali = listaSpecieTotali.sort((a, b) => {
    let nameA = a.value.toUpperCase();
    let nameB = b.value.toUpperCase();
    if (nameA < nameB) return -1;
    else if (nameA > nameB) return 1;
    else return 0;
  });
  return listaSpecieTotali;
};

export const preparaClassiIspra = (data) => {
  let classiIspra = null;
  if (data && data !== undefined)
    classiIspra = data.map((classe) => ({
      id: classe.code.toString(),
      value: classe.name,
    }));
  return classiIspra;
};

export const preparaListaUnitaPopolazione = (data) => {
  let listaUnitaPopolazione = null;
  if (data && data !== undefined)
    listaUnitaPopolazione = data.map((unita) => ({
      id: unita.code.toString(),
      value: unita.name,
    }));
  return listaUnitaPopolazione;
};

export const preparaTarget = (data) => {
  let target = null;
  if (data && data !== undefined)
    target = data.map((t) => ({
      id: t.tipoTarget.toString(),
      value: t.descrTarget,
    }));
  target = target.sort((a, b) => {
    let nameA = a.value.toUpperCase();
    let nameB = b.value.toUpperCase();
    if (nameA < nameB) return -1;
    else if (nameA > nameB) return 1;
    else return 0;
  });
  return target;
};

export const preparaEntitaRilevate = (data) => {
  let entitaRilevate = null;
  if (data && data !== undefined)
    entitaRilevate = data.map((entita) => ({
      id: entita.tipoMonit.toString(),
      value: entita.descrMonit,
    }));
  entitaRilevate = entitaRilevate.sort((a, b) => {
    let nameA = a.value.toUpperCase();
    let nameB = b.value.toUpperCase();
    if (nameA < nameB) return -1;
    else if (nameA > nameB) return 1;
    else return 0;
  });
  return entitaRilevate;
};

export const preparaSottogruppi = (data) => {
  let sottogruppi = null;
  if (data && data !== undefined)
    sottogruppi = data.map((sottogruppo) => ({
      id: sottogruppo.tipoMonitSub.toString(),
      value: sottogruppo.descrMonitSub,
      entita: sottogruppo.tipoMonit.toString(),
    }));
  sottogruppi = sottogruppi.sort((a, b) => {
    let nameA = a.value.toUpperCase();
    let nameB = b.value.toUpperCase();
    if (nameA < nameB) return -1;
    else if (nameA > nameB) return 1;
    else return 0;
  });
  return sottogruppi;
};

export const preparaSviluppi = (data) => {
  let sviluppi = null;
  if (data && data !== undefined)
    sviluppi = data.map((sviluppo) => ({
      id: sviluppo.tipoStadioSvil.toString(),
      value: sviluppo.descrStadioSvil,
    }));
  sviluppi = sviluppi.sort((a, b) => {
    let nameA = a.value.toUpperCase();
    let nameB = b.value.toUpperCase();
    if (nameA < nameB) return -1;
    else if (nameA > nameB) return 1;
    else return 0;
  });
  return sviluppi;
};

export const preparaAbitudini = (data) => {
  let abitudini = null;
  if (data && data !== undefined)
    abitudini = data.map((abitudine) => ({
      id: abitudine.tipoAbitudini.toString(),
      value: abitudine.descrAbitudini,
    }));
  abitudini = abitudini.sort((a, b) => {
    let nameA = a.value.toUpperCase();
    let nameB = b.value.toUpperCase();
    if (nameA < nameB) return -1;
    else if (nameA > nameB) return 1;
    else return 0;
  });
  return abitudini;
};
