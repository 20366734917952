import React from "react";
import ReactDOM from "react-dom";
import { LDCore } from "ld_core";
import { appSagas } from "./store/appSagas";
import { appReducers } from "./store/appReducers";
import { App } from "./container/App";
import "./styles/index.scss";
import * as serviceWorker from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

const wrapper = document.getElementById("container");
wrapper &&
  ReactDOM.render(
    <LDCore
      withLogs={false}
      applicationSagas={appSagas}
      applicationReducers={appReducers}
      app={App}
      // cookieInfo={{ useCookie: true, infoLink: "https://www.regione.liguria.it/privacy.html" }}
      whitelist={["common"]}
    />,
    wrapper
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
