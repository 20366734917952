import Icon from "@ant-design/icons";

const AppleAppIcon = () => (
  <svg viewBox="0 0 63.21 84.21">
    <path
      className="cls-1apple"
      d="M39.76,26.81H53a7.85,7.85,0,0,1,7.71,8V73.74a7.84,7.84,0,0,1-7.71,8H10.22a7.85,7.85,0,0,1-7.72-8V34.79a7.85,7.85,0,0,1,7.72-8h11.9"
    />
    <path
      className="cls-2apple"
      d="M29.28,1,13.49,16.73a3.24,3.24,0,0,0,0,4.59,3.3,3.3,0,0,0,4.62,0L28.44,11V50.39a3.24,3.24,0,0,0,6.48,0V11L45.16,21.32a3.23,3.23,0,1,0,4.56-4.58L33.92,1a3.31,3.31,0,0,0-4.64,0Z"
    />
  </svg>
);

export const AppleApp = (props) => <Icon component={AppleAppIcon} {...props} />;
