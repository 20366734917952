export const PATH_HOME = "/";
export const PATH_CERCA = "/cerca";
export const PATH_SEGNALA = "/segnala";
export const PATH_MAPPA_SPECIE_ALIENE = "/mappaSpecieAliene";
export const PATH_LOGIN = "/applibioss/pro/login";
export const PATH_LOGOUT = "/applibioss/pro/login/callback?logout=";
export const PATH_DOWNLOAD = "pro/download/osservazioni";
export const PATH_PRO = "/private";
export const PATH_SEGNALAZIONI = "/segnalazioni";
export const PATH_VISUALIZZA_SEGNALAZIONE = "/visualizzaSegnalazione";
export const PATH_CREDITS = "/credits";
export const PATH_BASE_DATI = "https://biodivpub.regione.liguria.it/Biodiv.aspx?CMD=Consulta_Bio&PAR1=LIBIOSS_PUB";
export const PATH_ARPAL = "https://www.arpal.liguria.it/";
export const PATH_NOTE_LEGALI_ARPAL = "https://www.arpal.liguria.it/note-legali.html";
export const PATH_PRIVACY_ARPAL = "https://www.arpal.liguria.it/privacy.html";
export const PATH_NO_SPID = "https://www.arpal.liguria.it/l-agenzia/prodotti-e-servizi/spid-point.html";
export const PATH_DICH_ACCESSIBILITA = "https://form.agid.gov.it/view/40951540-7717-11ef-af76-0fc9519e5de6";