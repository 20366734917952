import Icon from "@ant-design/icons";

const NoteLegaliIcon = () => (
  <svg
    aria-hidden="true"
    aria-label="Icona banca dati"
    focusable="false"
    role="img"
    className="svg-inline--fa fa-note-legali fa-w-16"
    viewBox="0 0 67.21 86.87"
  >
    <rect className="cls-1" x="1.5" y="36.1" width="64.21" height="49.28" rx="6.12" />
    <path className="cls-2" d="M15.29,36.1V27.8A18.26,18.26,0,0,1,33.54,9.54h0A18.27,18.27,0,0,1,51.8,27.8v8.3" />
    <path className="cls-3" d="M8.78,36.1V21.55c0-11.07,11.14-20,24.89-20h0c13.75,0,24.89,9,24.89,20.05V36.1" />
    <path className="cls-4" d="M37,60.44v8.3a3.08,3.08,0,0,1-3.09,3.08h0a3.08,3.08,0,0,1-3.08-3.08v-8" />
    <path className="cls-4" d="M37,61.37a6.21,6.21,0,1,0-6.17.38" />
  </svg>
);

export const NoteLegali = (props) => <Icon component={NoteLegaliIcon} {...props} />;
